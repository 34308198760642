<template>
	<div class="xf-file-vector">
		<img src="../../assets/pics/file.png">
		<span class="xf-file-vector-text" v-if="icon == undefined">{{text}}</span>
		<div class="xf-file-vector-icon" v-else><i :class="icon"></i></div>
	</div>
</template>

<script>
export default {
	props: {
		text: String,
		icon: String
	}
}
</script>

<style lang="scss" scoped>
.xf-file-vector {
	width: max-content;
	height: 63px;
	display: flex;
	justify-content: center;
	position: relative;

	img {
		height: 63px;
	}

	.xf-file-vector-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 12px;
		font-weight: 600;
	}

	.xf-file-vector-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		i {
			font-size: 14px;
		}
	}
}
</style>