<template>
	<div class="documents" v-if="!loading.documents">
		<xf-tabs :items="projects" v-model="selectedProject"></xf-tabs>

		<div class="project-details">
			<div class="row my-gutters">

				<div class="col-12">
					<xf-section-card class="documents-card" title="All Contracts" style="height: 422px">
						<div class="empty-card" v-if="selectedProjectData.contracts.length == 0">
							<img class="empty-card-pic" src="../../../assets/pics/empty_files.png">
							<div class="empty-card-text">There are no files right now</div>
						</div>

						<div class="documents-card-content-wrapper" v-else>
							<div class="content">
								<div class="file-item" v-for="item in selectedProjectData.contracts" :key="item.attachment_id">
									<xf-file-wrapper :file_name="item.attachment_url" :url="item.download_url" />
								</div>
							</div>
						</div>
					</xf-section-card>
				</div>

				<div class="col-12">
					<xf-section-card class="documents-card" title="All Documents" style="height: 422px">
						<div class="empty-card" v-if="selectedProjectData.documents.length == 0">
							<img class="empty-card-pic" src="../../../assets/pics/empty_files.png">
							<div class="empty-card-text">There are no files right now</div>
						</div>

						<div class="documents-card-content-wrapper" v-else>
							<div class="content">
								<div class="file-item" v-for="item in selectedProjectData.documents" :key="item.attachment_id">
									<xf-file-wrapper :file_name="item.attachment_url" :url="item.download_url" />
								</div>
							</div>
						</div>
					</xf-section-card>
				</div>

				<div class="col-12">
					<xf-section-card class="documents-card" title="All Assets" style="height: 422px">
						<div class="empty-card" v-if="selectedProjectData.assets.length == 0">
							<img class="empty-card-pic" src="../../../assets/pics/empty_files.png">
							<div class="empty-card-text">There are no files right now</div>
						</div>

						<div class="documents-card-content-wrapper" v-else>
							<div class="content">
								<div class="file-item" v-for="item in selectedProjectData.assets" :key="item.attachment_id">
									<xf-file-wrapper :file_name="item.attachment_url" :url="item.download_url" />
								</div>
							</div>
						</div>
					</xf-section-card>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading:{
				documents: false,
			},
			projects: [],
			selectedProject: 0,
			files: {},
		}
	},
	computed: {
		selectedProjectData(){
			var project_name = this.projects[this.selectedProject];
			return this.files[project_name];
		}
	},
	created() {
		/* set loading */
		this.loading.documents = true;
		this.$root.$emit('panelContainerLoading', true);

		/* call the api */
		const config = {
			headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
		};

		this.$http.get('overview/documents', config)
			.then((response) => {
				var responseData = response.data.data;
				this.projects = responseData.projects;
				this.files = responseData.files;
			})
			.catch((e) => {
				if (e.response.status === 401) {
					/* auth error, redirect user to login page */
					this.$store.commit("unsetUserData");
					this.$router.push("/login");
				}
			})
			.finally(() => {
				this.loading.documents = false;
				this.$root.$emit('panelContainerLoading', false);
			});
	},
	destroyed() {
		this.$root.$emit('panelContainerLoading', false);
	},
	mounted() {
		this.$root.$emit('setPanelTitleText' , "Documents / Assets");
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables";

.documents {
	.project-details {
		padding-top: 64px;

		@include only-phone-bs-lg{
			padding-top: 32px;
		}
	}

	.documents-card {
		.documents-card-content-wrapper {
			display: flex;
			flex-direction: column;
			height: 100%;
			margin: -8px;

			.content {
				flex: 1;
				display: flex;
				flex-wrap: wrap;

				.file-item {
					width: max-content;
					padding: 8px;
				}
			}
		}
	}
}
</style>
