<template>
	<div class="create-password-container">
		<div class="panel-loading" v-if="loading.validateHash">
			<xf-loading :size="64" />
		</div>

		<div class="create-password-card" v-else>
			<div class="create-password-logo">
				<img src="../../assets/logo.png" alt="xdigital">
			</div>

			<h1 class="create-password-title">Create Password</h1>

			<xf-input class="password-input" id="password-input" label="Password" type="password" placeholder="Enter your password" v-model="createPasswordData.password1" />
			<xf-input class="password-input" id="password-input" label="Re-Enter password" type="password" placeholder="Re-Enter your password" v-model="createPasswordData.password2" />
			<xf-btn class="create-password-btn" :block="true" btnstyle="primary" :loading="loading.setPassword" :disabled="!isDataFilled" @click="setPassword">
				Create password
			</xf-btn>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				validateHash: false,
				setPassword: false,
			},
			createPasswordData: {
				password1: "",
				password2: "",
			}
		}
	},
	computed: {
		isDataFilled() {
			if (this.createPasswordData.password1 == "" || this.createPasswordData.password2 == "") return false;
			if (this.createPasswordData.password1 != this.createPasswordData.password2) return false;
			return true;
		}
	},
	methods: {
		validateHash() {
			this.loading.validateHash = true;

			var data = {

			}

			this.$http.get('user/invite/' + this.$route.params.hash, data)
				.then(response => {
					var responseData = response.data.data;
					if (!responseData.is_link_valid) this.$router.push("/login");
				})
				.catch(error => {
					error;
					this.$router.push("/login");
				})
				.finally(() => {
					this.loading.validateHash = false;
				});
		},
		setPassword() {
			this.loading.setPassword = true;

			var data = {
				password: this.createPasswordData.password1
			}

			this.$http.post('user/invite/' + this.$route.params.hash, data)
				.then(() => {
					this.$toast.success("You were successfully invited! Plase login");
					setTimeout(() => {
						this.$router.push("/login");
					} , 2000)
				})
				.catch(() => {
					this.$toast.error("Can't set the password!");
				})
				.finally(() => {
					this.loading.setPassword = false;
				});
		}
	},
	created() {
		this.validateHash();
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.create-password-container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $light-bg-color;
	overflow: auto;

	@include only-phone {
		align-items: flex-start;
	}

	.create-password-card {
		background: #fff;
		padding: 32px;
		border-radius: 12px;
		width: 100%;
		max-width: 520px;

		@include only-phone {
			margin: 50px 16px;
			padding: 16px 12px;
		}

		.create-password-logo {
			display: flex;
			justify-content: center;
			margin-bottom: 32px;

			img {
				width: 233px;
				align-self: start; //fix safari stretched images (auto height)
			}
		}

		.create-password-title {
			font-weight: bold;
			font-size: 18px;
			line-height: 22px;
			letter-spacing: 0.2px;
			color: #1c1c28;
			text-align: center;
		}

		.password-input {
			margin-bottom: 16px;

			@include only-phone {
				margin-bottom: 12px;
			}
		}

		.create-password-btn {
			margin-top: 32px;

			@include only-phone {
				margin-top: 18px;
				margin-bottom: 10px;
			}
		}
	}
}
</style>