<template>
	<div class="account-settings-tab" v-if="!loading.contact_us">
		<div class="row my-gutters">
			<div class="col-12 col-lg-6">
				<xf-section-card class="account-settings" title="Account Settings">
					<div class="profile-photo">
						<xf-profile-avatar :loading="loading.profileUpdate" class="settings-profile-avatar" :img="$store.state.User.data.profile" />
						<button class="add-photo-btn" @click.prevent="$refs.prof_pic_input.click()">Add Profile Photo</button>
						<input class="d-none" ref="prof_pic_input" type="file" @change="handleProfPicInput" />
					</div>

					<div class="user-info">
						<form @submit.prevent="updateUserData">
							<xf-input class="user-info-input" id="fullname-input" label="Fullname" type="text" placeholder="Name And Family Name" v-model="account_settings.full_name" />
							<xf-input class="user-info-input" id="email-input" label="Email" type="email" placeholder="Your Email" v-model="account_settings.email" :disabled="true" />
							<xf-input class="user-info-input mb-0" id="password-input" label="Phone number (Optional)" type="tel" placeholder="+123456789" v-model="account_settings.phone" />

							<div class="user-2fa">
								<span class="user-2fa-title">Two-factor authentication</span>
								<xf-check-box :leftLabeled="true" text="Enabled" v-model="account_settings.twoFA" @click="handleTwoFA" />
							</div>

							<div class="user-info-btns">
								<xf-btn :loading="loading.user_update">
									Submit
								</xf-btn>
							</div>
						</form>
					</div>
				</xf-section-card>
			</div>
			<div class="col-12 col-lg-6">
				<xf-section-card class="contact-us" title="Contact us">
					<div class="contact-us-items">
						<div class="contact-us-item">
							<div class="contact-us-icon"><i class="far fa-envelope"></i></div>
							<div class="contact-us-value">{{contact_us.email}}</div>
						</div>
					</div>

					<div class="contact-us-items">
						<div class="contact-us-item">
							<div class="contact-us-icon"><i class="far fa-phone"></i></div>
							<div class="contact-us-value">+{{contact_us.phone}}</div>
						</div>
						<div class="phone-alert">Calling Only for emergency purposes if you have basic plan.</div>
					</div>
				</xf-section-card>
			</div>
		</div>

		<TwoFA />
		<DisableTwoFA />
	</div>
</template>

<script>
import TwoFA from "./TwoFA"
import DisableTwoFA from "./DisableTwoFA"

export default {
	data() {
		return {
			loading: {
				contact_us: false,
				user_update: false,
				profileUpdate: false,
			},
			account_settings: {
				full_name: this.$store.state.User.data.user_full_name,
				email: this.$store.state.User.data.user_email,
				phone: "+" + this.$store.state.User.data.user_phone,
				twoFA: this.$store.state.User.data.twoFA,
			},
			contact_us: {

			},
			twoFA: {
				show: false,
				loading: false,
				enabled: false,
				step: "",
				ga_code: "",
				data: {
					qr_image: "",
					qr_code: ""
				}
			},
			disableTwoFA: {
				show: false,
				disabled: false,
			}
		}
	},
	components: {
		TwoFA,
		DisableTwoFA
	},
	methods: {
		updateUserData() {
			/* set loading */
			this.loading.user_update = true;

			/* call the api */
			const data = {
				fullname: this.account_settings.full_name,
				phone: this.account_settings.phone
			}

			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			this.$http.put('user/update', data, config)
				.then((response) => {
					this.$toast.success("Data updated!");
					var responseData = response.data.data;
					this.$store.commit("setUserData", {
						persist: this.$store.state.User.data_cached,
						userData: {
							user_email: responseData.user_email,
							user_phone: responseData.user_phone,
							user_full_name: responseData.user_full_name,
						}
					})
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error("Can't update data!");
					}
				})
				.finally(() => {
					this.loading.user_update = false;
				});
		},
		handleTwoFA() {
			if (this.account_settings.twoFA) {
				/* reset values */
				this.twoFA.step = "scanCode";
				this.twoFA.enabled = false;
				this.twoFA.ga_code = "";


				/* set loading */
				this.twoFA.loading = true;
				this.twoFA.show = true;

				/* call the api */
				const config = {
					headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
				};

				this.$http.get('2fa/enable', config)
					.then((response) => {
						var responseData = response.data.data;
						this.twoFA.data.qr_image = responseData.qr_image;
						this.twoFA.data.qr_code = responseData.qr_code;
					})
					.catch((e) => {
						if (e.response.status === 401) {
							/* auth error, redirect user to login page */
							this.$store.commit("unsetUserData");
							this.$router.push("/login");
						}
					})
					.finally(() => {
						this.twoFA.loading = false;
					});
			} else {
				this.disableTwoFA.show = true;
			}
		},
		handleProfPicInput(e) {
			var file = e.target.files[0];

			/* set loading */
			this.loading.profileUpdate = true;

			/* call the api */
			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			var data = new FormData();
			data.append('file_asset', file);

			this.$http.post('user/upload/photo', data, config)
				.then((response) => {
					var responseData = response.data.data;
					this.$store.commit("setUserData", {
						persist: this.$store.state.User.data_cached,
						userData: {
							profile: responseData.attachment_url
						}
					})
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error("Can't update the profile!");
					}
				})
				.finally(() => {
					this.loading.profileUpdate = false;
				});

		},
	},
	created() {
		/* set loading */
		this.loading.contact_us = true;
		this.$root.$emit('panelContainerLoading', true);

		/* call the api */
		const config = {
			headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
		};

		this.$http.get('contactus', config)
			.then((response) => {
				var responseData = response.data.data;
				this.contact_us = responseData;
			})
			.catch((e) => {
				if (e.response.status === 401) {
					/* auth error, redirect user to login page */
					this.$store.commit("unsetUserData");
					this.$router.push("/login");
				}
			})
			.finally(() => {
				this.loading.contact_us = false;
				this.$root.$emit('panelContainerLoading', false);
			});
	},
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables";

.account-settings-tab {
	.account-settings {
		.profile-photo {
			.settings-profile-avatar {
				width: 74px;
				height: 74px;
				margin-bottom: 20px;
			}
			.add-photo-btn {
				font-size: 14px;
				line-height: 24px;
				color: #0063f7;
				background: none;
			}
		}

		.user-info {
			padding-top: 32px;

			.user-info-input {
				margin-bottom: 16px;
			}

			.user-2fa {
				margin-top: 32px;
				display: flex;

				.user-2fa-title {
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
					letter-spacing: 0.2px;
					color: #1c1c28;

					@include only-phone-bs-lg {
						font-size: 14px;
						line-height: 17px;
					}
				}

				.xf-input-wrapper {
					margin-left: auto;
				}
			}

			.user-info-btns {
				margin-top: 24px;
			}
		}
	}

	.contact-us {
		height: 100%;
	}

	.contact-us-items {
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: 0;
		}

		.contact-us-item {
			display: flex;
			align-items: center;

			.contact-us-icon {
				margin-right: 12px;

				i {
					color: #8f90a6;
					font-size: 24px;
				}
			}

			.contact-us-value {
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 0.2px;
				color: #1c1c28;
			}
		}

		.phone-alert {
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.2px;
			color: #ff3b3b;
			margin-top: 12px;
		}
	}
}
</style>