<template>
	<div class="xf-tabs">
		<div class="xf-tabs-items-wrapper" ref="items-wrapper">
			<div class="xf-tab-indicator" :style="indicator"></div>
			<ul class="xf-tabs-items">
				<li class="xf-tab-item" ref="tab_item" :class="[(index == activeTab) ? 'active' : '']" v-for="(listItem , index) in items" :key="index" @click="selectTab($event.target , index)">{{listItem}}</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeTab: null,
			indicator: {
				left: null,
				width: null,
			}
		}
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		}
	},
	methods: {
		selectTab(element, tabIndex) {
			this.activeTab = tabIndex;
			this.$emit('input', this.activeTab);

			/* set button indicator */
			let btn_left_offset = element.getBoundingClientRect().left - this.$refs['items-wrapper'].getBoundingClientRect().left + this.$refs['items-wrapper'].scrollLeft;
			let btn_width = element.offsetWidth;
			this.indicator = {
				left: btn_left_offset + 'px',
				width: btn_width + 'px',
			};
		},
		scrollWrapper: function (offset) {
			this.$refs['items-wrapper'].style.overflow = "scroll";
			if (this.$refs['items-wrapper'].scrollLeft < this.$refs['items-wrapper'].scrollLeftMax || this.$refs['items-wrapper'].scrollLeft > 0) {
				this.$refs['items-wrapper'].scrollLeft = offset;
			}
			this.$refs['items-wrapper'].style.overflow = "hidden";
			return this.$refs['items-wrapper'].scrollLeft;
		}
	},
	mounted() {
		if (this.items.length > 0) {
			this.selectTab(this.$refs.tab_item[0], 0)
		}
	}
}
</script>

<style lang="scss" scoped>
.xf-tabs {
	overflow-x: auto;
	padding-bottom: 10px;

	.xf-tabs-items-wrapper {
		position: relative;
		padding: 12px 0;
		width: 100%;
		min-width: max-content;

		&::before {
			content: "";
			border-bottom: 1px solid #c7c9d9;
			position: absolute;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			left: 0;
			right: 0;
			pointer-events: none;
			bottom: 1px;
		}

		.xf-tab-indicator {
			width: 0;
			height: 3px;
			background-color: #0063f7;
			position: absolute;
			bottom: 0px;
			left: 0;
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			z-index: 0;
		}

		.xf-tabs-items {
			display: flex;
			align-items: center;

			.xf-tab-item {
				margin-right: 40px;
				font-weight: 600;
				font-size: 16px;
				line-height: 28px;
				color: #8f90a6;
				white-space: nowrap;
				cursor: pointer;

				&.active {
					color: #0063f7;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
</style>