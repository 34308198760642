<template>
	<div class="two-fa-container">
		<div class="two-fa-card">
			<div class="two-fa-icon">
				<img src="../../assets/pics/twofa.png" alt="two-factor authentication">
			</div>

			<h1 class="two-fa-title">Enter the authentication code</h1>
			<h1 class="two-fa-desc">the code has been send to your authentication app</h1>

			<xf-auth-code class="two-fa-input" @input="e => $parent.loginData.twoFA = e" />

			<xf-btn class="two-fa-btn" :block="true" btnstyle="primary" :disabled="!isDataFilled" @click="$parent.loginUser()" :loading="$parent.loading.login">
				Verify
			</xf-btn>

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
		}
	},
	computed: {
		isDataFilled() {
			if (this.$parent.loginData.twoFA.length != 6) return false;
			return true;
		}
	},
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.two-fa-container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $light-bg-color;
	overflow: auto;

	@include only-phone {
		align-items: flex-start;
	}

	.two-fa-card {
		background: #fff;
		padding: 32px;
		border-radius: 12px;
		width: 100%;
		max-width: 520px;

		@include only-phone {
			margin: 50px 16px;
			padding: 16px 12px;
		}

		.two-fa-icon {
			display: flex;
			justify-content: center;
			margin-bottom: 32px;

			img {
				width: 257px;
				align-self: start; //fix safari stretched images (auto height)
			}
		}

		.two-fa-title {
			font-weight: bold;
			font-size: 18px;
			line-height: 22px;
			letter-spacing: 0.2px;
			color: #1c1c28;
			text-align: center;
		}

		.two-fa-desc {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #8f90a6;
			text-align: center;
			margin-top: 26px;
			margin-bottom: 32px;
		}

		.two-fa-input {
			margin-bottom: 32px;
		}
	}
}
</style>