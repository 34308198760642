export default {
	state: {
		data: {
			newNotifications: []
		}
	},
	mutations: {
		setNewNotifications(state, payload) {
			state.data.newNotifications = payload;
		},
		readUnreadNotifications(state) {
			state.data.newNotifications.forEach((item) => {
				item.notification_is_readed = 1;
			})
		}
	},
	getters: {
		userHasNewNotifications(state) {
			var hasUnread = false;
			if (state.data.newNotifications.length > 0) {
				state.data.newNotifications.forEach((item) => {
					if (item.notification_is_readed == 0) hasUnread = true;
				})
			}
			return hasUnread;
		},
		newNotifications(state) {
			var new_notifications = [];
			state.data.newNotifications.forEach((item) => {
				if (item.notification_is_readed == 0) new_notifications.push(item);
			})
			return new_notifications;
		}
	},
	actions: {
	},
};
