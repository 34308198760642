import Vue from 'vue'

/* global */
import XfProfileAvatar from './global/XfProfileAvatar.vue'
import XfDivider from './global/XfDivider.vue'
import XfFileVector from './global/XfFileVector.vue'
import XfFeedback from './global/XfFeedback.vue'
import XfSectionCard from './global/XfSectionCard.vue'
import XfBadge from './global/XfBadge.vue'
import XfLoading from './global/XfLoading.vue'
import XfTabs from './global/XfTabs.vue'
import XfNotifications from './global/XfNotifications.vue'
import XfUploadLoading from './global/XfUploadLoading.vue'
import XfModal from './global/XfModal.vue'
import XfAlert from './global/XfAlert.vue'
import XfConfirm from './global/XfConfirm.vue'
import XfTimeline from './global/XfTimeline.vue'
import XfFileWrapper from './global/XfFileWrapper.vue'
Vue.component('xf-profile-avatar', XfProfileAvatar)
Vue.component('xf-divider', XfDivider)
Vue.component('xf-file-vector', XfFileVector)
Vue.component('xf-feedback', XfFeedback)
Vue.component('xf-section-card', XfSectionCard)
Vue.component('xf-badge', XfBadge)
Vue.component('xf-loading', XfLoading)
Vue.component('xf-tabs', XfTabs)
Vue.component('xf-notification', XfNotifications)
Vue.component('xf-upload-loading', XfUploadLoading)
Vue.component('xf-modal', XfModal)
Vue.component('xf-alert', XfAlert)
Vue.component('xf-confirm', XfConfirm)
Vue.component('xf-timeline', XfTimeline)
Vue.component('xf-file-wrapper', XfFileWrapper)

/* inputs */
import XfInput from './inputs/XfInput'
import XfCheckBox from './inputs/XfCheckBox.vue'
import XfSelectInput from './inputs/XfSelectInput.vue'
import XfTextarea from './inputs/XfTextarea.vue'
import XfAuthCode from './inputs/XfAuthCode.vue'
Vue.component('xf-input', XfInput)
Vue.component('xf-textarea', XfTextarea)
Vue.component('xf-check-box', XfCheckBox)
Vue.component('xf-select-input', XfSelectInput)
Vue.component('xf-auth-code', XfAuthCode)

/* btns */
import XfBtn from './btns/XfBtn'
Vue.component('xf-btn', XfBtn)

/* chat */
import XfChatHeader from './chat/XfChatHeader'
import XfChatMessages from './chat/XfChatMessages'
import XfChatFooter from './chat/XfChatFooter'
import XfChat from './chat/XfChat'
Vue.component('xf-chat-header', XfChatHeader)
Vue.component('xf-chat-messages', XfChatMessages)
Vue.component('xf-chat-footer', XfChatFooter)
Vue.component('xf-chat', XfChat)

/* panel */
import XfSidebar from './panel/XfSidebar.vue'
Vue.component('xf-sidebar', XfSidebar)
