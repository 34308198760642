<template>
	<div class="xf-timeline">
		<div class="xf-timeline-top">
			<div class="xf-start-end">
				<div class="xf-start">
					<div class="xf-start-end-title">Start Date</div>
					<div class="xf-start-end-value">
						<slot name="start_date"></slot>
					</div>
				</div>

				<div class="xf-arrow">
					<i class="fas fa-arrow-right"></i>
				</div>

				<div class="xf-end">
					<div class="xf-start-end-title">End Date</div>
					<div class="xf-start-end-value">
						<slot name="end_date"></slot>
					</div>
				</div>
			</div>
		</div>

		<div class="xf-timeline-content" ref="timeline">
			<div class="xf-timeline-progress">
				<div class="xf-timeline-progress-bar" ref="xf_timeline_progress_bar"></div>
			</div>

			<div class="xf-timeline-steps">
				<div class="xf-timeline-step" v-for="(step, index) in steps" :key="index" :state="step.state" ref=timeline_step>
					<div class="xf-timeline-step-line"></div>

					<div class="xf-timeline-step-content">
						<div class="xf-timeline-step-indicator-wrapper">
							<div class="xf-timeline-step-indicator" :class="[(step.state) == 'active' ? 'active' : '' , (step.state == 'finished') ? 'finished' : '']">
								<i class="xf-timeline-step-indicator-finish-icon fas fa-check"></i>
							</div>
						</div>
						<div class="xf-timeline-step-text">{{step.title}}</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			//
		}
	},
	props: [
		"steps"
	],
	watch: {
		steps(){
			//render progress bar in case of changing data
			setTimeout(this.renderProgressBar , 5);
		}
	},
	methods: {
		activeStep() {
			if(this.steps.length == 0) return false;

			var timeline_steps = this.$refs.timeline_step;
			for (var i = 0; i < timeline_steps.length; i++) {
				if (timeline_steps[i].getAttribute('state') == 'active') return timeline_steps[i];
			}
			return undefined;
		},
		renderProgressBar() {
			if(this.activeStep() == undefined) return false;

			/* short time out for working properly */
			setTimeout(() => {
				var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
				var timeline = this.$refs.timeline;
				var active_step = this.activeStep();
				var xf_timeline_progress_bar = this.$refs.xf_timeline_progress_bar;
	
	
				/* desktop */
				if (vw > 1140) {
					var active_step_x = active_step.getBoundingClientRect().x - timeline.getBoundingClientRect().x;
					var progress_bar_width = active_step_x + 5;
					xf_timeline_progress_bar.style.width = progress_bar_width + "px";
					xf_timeline_progress_bar.style.height = "inherit";
				} else {
					var active_step_y = active_step.getBoundingClientRect().y - timeline.getBoundingClientRect().y;
					var progress_bar_height = active_step_y + 10;
					xf_timeline_progress_bar.style.width = "inherit";
					xf_timeline_progress_bar.style.height = progress_bar_height + "px";
				}
			} , 5);
		}
	},
	mounted() {
		this.renderProgressBar();
	},
	created() {
		window.addEventListener("resize", this.renderProgressBar);
	},
	destroyed() {
		window.removeEventListener("resize", this.renderProgressBar);
	},
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/_variables";

.xf-timeline {
	overflow: hidden;

	.xf-timeline-top {
		display: flex;

		.xf-start-end {
			margin-left: auto;
			display: flex;
			align-items: center;

			.xf-start,
			.xf-end {
				.xf-start-end-title {
					font-weight: 600;
					font-size: 13px;
					line-height: 16px;
					text-align: center;
					letter-spacing: 0.2px;
					color: #1c1c28;
					margin-bottom: 9px;
				}

				.xf-start-end-value {
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
					text-align: center;
					letter-spacing: 0.2px;
					color: #8f90a6;

					@include only-phone-md {
						font-size: 12px;
						line-height: 15px;
					}
				}
			}
			.xf-arrow {
				margin: 0 21px;
			}
		}
	}

	.xf-timeline-content {
		margin-top: 23px;
		display: flex;
		flex-direction: column;

		@include only-phone-md {
			flex-direction: row;
		}

		.xf-timeline-progress {
			background: #e5e5e5;
			border-radius: 15px;
			overflow: hidden;
			width: 100%;
			height: 12px;

			@include only-phone-md {
				width: 12px;
				height: initial;
			}

			.xf-timeline-progress-bar {
				background: #0063f7;
				border-radius: 15px;
				height: 100%;
				width: 0%;
				transition: width 200ms ease;
				

				@include only-phone-md {
					width: 100%;
					height: inherit;
				}
			}
		}

		.xf-timeline-steps {
			padding: 0 48px;
			display: flex;

			@include only-phone-md {
				flex-direction: column;
				flex: 1;
				padding: 40px 0;
			}

			.xf-timeline-step {
				flex: 1;
				display: flex;
				flex-direction: column;

				@include only-phone-md {
					flex-direction: row;
					align-items: center;
					margin-bottom: 70px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.xf-timeline-step-line {
					height: 70px;
					width: 2px;
					border-left: 2px dashed #e5e5e5;
					margin-bottom: 6px;

					@include only-phone-md {
						height: 2px;
						width: initial;
						flex: 1;
						border-left: none;
						border-top: 2px dashed #e5e5e5;
						margin-bottom: 0px;
						margin-right: 6px;
					}
				}

				.xf-timeline-step-content {
					display: flex;
					align-items: center;

					.xf-timeline-step-indicator-wrapper {
						margin-right: 8px;

						.xf-timeline-step-indicator {
							width: 11px;
							height: 11px;
							background: #ffffff;
							border: 3px solid #c7c9d9;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;

							.xf-timeline-step-indicator-finish-icon {
								font-size: 7px;
								color: #fff;
								display: none;
							}

							&.active {
								border-color: #0063f7;
							}

							&.finished {
								border: none;
								background: #0063f7;

								.xf-timeline-step-indicator-finish-icon {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>