<template>
	<div class="team-members-tab" v-if="!loading.members">
		<div class="row my-gutters">
			<div class="col-12">
				<xf-section-card class="team-members-card" title="Team Members">
					<template v-slot:header-right>
						<xf-btn :compact="true" @click="showInviteMember = true">
							Invite People
						</xf-btn>
					</template>

					<div class="team-members-wrapper">
						<ul class="team-members">
							<li class="team-member-row" v-for="(item , index) in members" :key="index">
								<xf-profile-avatar class="team-member-profile" :img="item.user_profile_photo" />
								<div class="team-member-name">
									{{item.user_full_name}}
								</div>
								<div class="team-member-email">
									{{item.user_email}}
								</div>
								<div class="team-member-role">
									<span class="name-desc" v-if="item.is_current">You</span>
									<RoleSelector v-else :role="item.user_role" @click="toggleRoleSelector(item.user_id , item.user_role)" @remove="toggleRemoveUser(item.user_id)" @changeRole="changeRole(item.user_id , $event)" :class="{open: item.user_id == role_selector_user_id}" />
								</div>
							</li>
						</ul>
					</div>
				</xf-section-card>
			</div>
		</div>

		<xf-modal class="invite-member" v-model="showInviteMember">
			<div class="invite-member-header">
				<button class="close-btn" @click="showInviteMember = false">
					<i class="fas fa-times"></i>
				</button>
				<div class="invite-member-title">Invite a team member</div>
			</div>

			<div class="invite-member-content">
				<div class="invite-member-desc">
					You can invite a team member by putting their email below. An confirmation email will be send to them and they have to create a password and after that they can login.
				</div>

				<form class="invite-member-form" @submit.prevent="inviteMember">
					<xf-input label="Email" id="invite-email-input" v-model="inviteMemberEmail" />
					<xf-btn class="invite-member-send-btn" :loading="loading.inviteMember">
						Send
					</xf-btn>
				</form>
			</div>
		</xf-modal>

		<xf-confirm denyText="Remove" cancelText="Cancel" @deny="removeUser" @cancel="cancelRemoving" :denyLoading="loading.removeMember" v-model="showRemoveMember">
			<template v-slot:title>Remove user</template>
			<template v-slot:text>Are you sure you want to remove this user?</template>
		</xf-confirm>
	</div>
</template>

<script>
import RoleSelector from "./RoleSelector"

export default {
	data() {
		return {
			showInviteMember: false,
			showRemoveMember: false,
			loading: {
				members: false,
				inviteMember: false,
				removeMember: false,
			},
			members: [],
			inviteMemberEmail: "",
			role_selector_user_id: null,
			role_selector_role: null,
			remove_user_id: null,
		}
	},
	components: {
		RoleSelector
	},
	methods: {
		toggleRoleSelector(user_id, user_role) {
			if (this.role_selector_user_id != user_id) { this.role_selector_user_id = user_id; this.role_selector_role = user_role }
			else { this.role_selector_user_id = null; this.role_selector_role = null; }
		},
		resetToggleRoleSelector() {
			this.role_selector_user_id = null;
			this.role_selector_role = null;
		},
		toggleRemoveUser(user_id) {
			this.remove_user_id = user_id;
			this.showRemoveMember = true;

			/* reset role selector */
			this.resetToggleRoleSelector();
		},
		cancelRemoving() {
			this.remove_user_id = null;
			this.showRemoveMember = false;
		},
		changeRole(user_id, new_role) {
			if (this.role_selector_role != new_role) {
				/* change user role */
				const data = {
					member_id: user_id,
					role: new_role
				}

				const config = {
					headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
				};

				this.$http.post('user/member/change-role', data, config)
					.catch((e) => {
						if (e.response.status === 401) {
							/* auth error, redirect user to login page */
							this.$store.commit("unsetUserData");
							this.$router.push("/login");
						} else {
							this.$toast.error("Can't update data!");
						}
					});
			}

			/* update user role */
			var member_index = this.members.findIndex(item => item.user_id == user_id);
			this.members[member_index].user_role = new_role;

			/* reset role selector */
			this.resetToggleRoleSelector();
		},
		removeUser() {
			/* set loading */
			this.loading.removeMember = true;

			/* call the api */
			const data = {
				member_id: this.remove_user_id,
			}

			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			this.$http.delete('user/member/remove', data, config)
				.then(() => {
					this.$toast.success("Member removed!");

					/* remove member */
					var member_index = this.members.findIndex(item => item.user_id == this.remove_user_id);
					if (member_index > -1) this.members.splice(member_index, 1);
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error("Can't remove the member!");
					}
				})
				.finally(() => {
					this.cancelRemoving();
					this.loading.removeMember = false;
				});
		},
		inviteMember() {
			/* set loading */
			this.loading.inviteMember = true;

			/* call the api */
			const data = {
				email: this.inviteMemberEmail,
			}

			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			this.$http.post('user/invite', data, config)
				.then(() => {
					this.$toast.success("Member invited!");
					this.inviteMemberEmail = "";
					this.showInviteMember = false;
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error(e.response.data.error.description);
					}
				})
				.finally(() => {
					this.inviteMemberEmail = "";
					this.showInviteMember = false;
					this.loading.inviteMember = false;
				});
		}
	},
	created() {
		/* set loading */
		this.loading.members = true;
		this.$root.$emit('panelContainerLoading', true);

		/* call the api */
		const config = {
			headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
		};

		this.$http.get('user/members', config)
			.then((response) => {
				var responseData = response.data.data;
				this.members = responseData;
			})
			.catch((e) => {
				if (e.response.status === 401) {
					/* auth error, redirect user to login page */
					this.$store.commit("unsetUserData");
					this.$router.push("/login");
				}
			})
			.finally(() => {
				this.loading.members = false;
				this.$root.$emit('panelContainerLoading', false);
			});
	}
}
</script>

<style lang="scss">
@import "../../../assets/styles/_variables";

.team-members-tab {
	.team-members-card {
		height: 500px;

		.team-members-wrapper {
			margin: 0 -32px;

			@include only-phone-bs-lg {
				margin: 0 -16px;
			}

			.team-members {
				width: 100%;
				min-width: max-content;
				padding: 0 32px;

				@include only-phone-bs-lg {
					padding: 0 16px;
				}

				.team-member-row {
					background: #ffffff;
					border: 1px solid #c7c9d9;
					box-sizing: border-box;
					border-radius: 8px;
					padding: 13px 24px;
					display: flex;
					align-items: center;
					position: relative;
					margin-bottom: 20px;
					white-space: nowrap;

					.team-member-profile {
						width: 38px;
						height: 38px;
						margin-right: 20px;

						i {
							font-size: 17px;
						}
					}

					.team-member-name {
						font-weight: 600;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0.2px;
						color: #1c1c28;
						margin-right: 35px;
					}

					.team-member-email {
						margin-right: 35px;
					}

					.team-member-role {
						margin-left: auto;
					}

					.name-desc {
						font-weight: 600;
						font-size: 14px;
						line-height: 17px;
						text-align: right;
						letter-spacing: 0.2px;
						color: #1c1c28;
					}
				}
			}
		}
	}

	.invite-member {
		.invite-member-header {
			display: flex;
			align-items: center;

			.close-btn {
				background: none;
				margin-right: 32px;

				i {
					font-size: 16px;
				}
			}

			.invite-member-title {
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.2px;
				color: #1c1c28;
			}
		}

		.invite-member-content {
			margin-top: 24px;

			.invite-member-desc {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.2px;
				color: #8f90a6;
			}

			.invite-member-form {
				margin-top: 28px;
				width: 100%;
				max-width: 450px;

				.invite-member-send-btn {
					margin-top: 28px;
				}
			}
		}
	}
}
</style>