<template>
	<div class="xf-input-wrapper">
		<div class="xf-check-box" @click="toggleCheckBox">
			<div class="check-text" v-if="leftLabeled">{{text}}</div>
			<div class="check-indicator" :class="[(leftLabeled) ? 'leftLabeled' : '' , (isChecked) ? 'active' : '']"></div>
			<div class="check-text" v-if="!leftLabeled">{{text}}</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isChecked: this.value,
		}
	},
	props: {
		text: {
			default: "",
		},
		leftLabeled: {
			default: false,
			type: Boolean,
		},
		value: {
			type: Boolean,
			default: false,
		}
	},
	watch: {
		value(newVal) {
			this.isChecked = newVal;
		}
	},
	methods: {
		toggleCheckBox() {
			this.isChecked = !this.isChecked;
			this.$emit('input', this.isChecked);
			this.$emit('click', this.$event);
		}
	}
}
</script>

<style lang="scss" scoped>
.xf-input-wrapper {
	.xf-check-box {
		display: flex;
		align-items: center;
		width: max-content;
		cursor: pointer;
		user-select: none;

		.check-indicator {
			background: #f4f6f3;
			border-radius: 6px;
			margin-right: 11px;
			width: 22px;
			height: 22px;

			&.leftLabeled {
				margin-right: 0;
				margin-left: 11px;
			}

			&.active {
				background: #0063f7;
			}
		}

		.check-text {
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}
	}
}
</style>