<template>
	<div class="xf-feedback">
		<div class="xf-feedback-item" v-for="(feedback, index) in feedbacks" :key="index" @click="selectFeedback(index)">
			<img v-if="index == activeFeedback" class="xf-feedback-pic" :src="require('../../assets/pics/feedbackFaces/' + feedback.activePic )">
			<img v-else class="xf-feedback-pic" :src="require('../../assets/pics/feedbackFaces/' + feedback.nonActivePic )">
			<span class="xf-feedback-text" :class="[index == activeFeedback ? 'active' : '']">{{feedback.title}}</span>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			feedbacks: [
				{
					title: "Not Good",
					nonActivePic: "sad.png",
					activePic: "sad_active.png",
				},
				{
					title: "Good",
					nonActivePic: "smile.png",
					activePic: "smile_active.png",
				},
				{
					title: "Excellent",
					nonActivePic: "happy.png",
					activePic: "happy_active.png",
				},
			],
			activeFeedback: null,
		}
	},
	methods: {
		selectFeedback(index) {
			this.activeFeedback = index;
		}
	}
}
</script>

<style lang="scss" scoped>
.xf-feedback {
	display: flex;

	.xf-feedback-item {
		margin-right: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
        cursor: pointer;

		&:last-child {
			margin-right: 0;
		}

		.xf-feedback-pic {
			width: 40px;
			height: 40px;
			margin-bottom: 16px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.xf-feedback-text {
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 0.2px;
			color: #1c1c28;

            &.active{
                color: #0063F7
            }
		}
	}
}
</style>