<template>
	<div class="login-container">
		<div class="login-card">
			<div class="login-logo">
				<img src="../../assets/logo.png" alt="xdigital">
			</div>

			<h1 class="login-title">Login</h1>

			<xf-input class="email-input" id="email-input" label="Email" type="email" placeholder="Enter your email" v-model="$parent.loginData.email" />
			<xf-input class="password-input" id="password-input" label="Password" type="password" placeholder="Enter your password" v-model="$parent.loginData.password" />
			<xf-check-box class="remember-me" text="Remember me" v-model="$parent.loginData.rememberMe" />
			<xf-btn class="login-btn" :block="true" btnstyle="primary" :disabled="!isDataFilled" @click="$parent.loginUser()" :loading="$parent.loading.login">
				Login
			</xf-btn>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		isDataFilled() {
			if (this.$parent.loginData.email == "" || !this.$global.validateEmail(this.$parent.loginData.email)) return false;
			if (this.$parent.loginData.password == "") return false;
			return true;
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.login-container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $light-bg-color;
	overflow: auto;

	@include only-phone {
		align-items: flex-start;
	}

	.login-card {
		background: #fff;
		padding: 32px;
		border-radius: 12px;
		width: 100%;
		max-width: 520px;

		@include only-phone {
			margin: 50px 16px;
			padding: 16px 12px;
		}

		.login-logo {
			display: flex;
			justify-content: center;
			margin-bottom: 32px;

			img {
				width: 233px;
				align-self: start; //fix safari stretched images (auto height)
			}
		}

		.login-title {
			font-weight: bold;
			font-size: 18px;
			line-height: 22px;
			letter-spacing: 0.2px;
			color: #1c1c28;
			text-align: center;
		}

		.email-input {
			margin-bottom: 16px;

			@include only-phone {
				margin-bottom: 12px;
			}
		}

		.password-input {
			margin-bottom: 32px;

			@include only-phone {
				margin-bottom: 24px;
			}
		}

		.remember-me {
			margin-bottom: 32px;

			@include only-phone {
				margin-bottom: 28px;
			}
		}
	}
}
</style>