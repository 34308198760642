<template>
	<div class="overview" v-if="!loading.overview">
		<xf-tabs :items="projects" v-model="selectedProject"></xf-tabs>

		<div class="project-details">
			<div class="row my-gutters">
				<div class="col-12" v-if="selectedProjectSteps.length > 0">
					<xf-section-card title="Timeline">
						<xf-timeline :steps="selectedProjectSteps">
							<template v-slot:start_date>{{selectedProjectData.project_start_date_formatted}}</template>
							<template v-slot:end_date>{{selectedProjectData.project_end_date_formatted}}</template>
						</xf-timeline>
					</xf-section-card>
				</div>

				<div class="col-12 col-lg-6">
					<xf-section-card title="Recent Activity Log" style="height: 422px">
						<div class="empty-card" v-if="selectedProjectData.logs.length == 0">
							<img class="empty-card-pic" src="../../../assets/pics/empty_files.png">
							<div class="empty-card-text">There are no notifications right now</div>
						</div>

						<ul class="recent-activities" v-else>
							<li :class="[(!item.notification_is_readed) ? 'not-read' : '']" v-for="item in selectedProjectData.logs" :key="item.notification_id">
								<span>{{item.notification_content}}</span>
							</li>
						</ul>
					</xf-section-card>
				</div>
				<div class="col-12 col-lg-6">
					<xf-section-card class="recently-files" title="Recently Added Files" style="height: 422px">
						<div class="empty-card" v-if="selectedProjectData.logs.length == 0">
							<img class="empty-card-pic" src="../../../assets/pics/empty_files.png">
							<div class="empty-card-text">There are no files right now</div>
						</div>

						<div class="recently-files-content-wrapper" v-else>
							<div class="content">
								<div class="file-item" v-for="item in selectedProjectData.assets" :key="item.attachment_id">
									<xf-file-wrapper :file_name="item.attachment_url" :url="item.download_url" />
								</div>
							</div>
						</div>

						<template v-slot:footer v-if="selectedProjectData.logs.length > 0">
							<div class="recently-files-footer">
								<button class="view-all-btn">View All Files</button>
							</div>
						</template>
					</xf-section-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				overview: false,
			},
			overview: [],
			projects: [],
			selectedProject: 0,
		}
	},
	computed: {
		selectedProjectData() {
			return this.overview.projects[this.selectedProject];
		},
		selectedProjectSteps() {
			var steps = [];
			var have_active = false;
			this.selectedProjectData.timeline.forEach((item) => {
				steps.push({
					state: (item.timeline_is_finished) ? "finished" : (!have_active) ? "active" : "inactive",
					title: item.timeline_title
				})
				if (!item.timeline_is_finished) have_active = true;
			})
			return steps;
		}
	},
	created() {
		/* set loading */
		this.loading.overview = true;
		this.$root.$emit('panelContainerLoading', true);

		/* call the api */
		const config = {
			headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
		};

		this.$http.get('overview', config)
			.then((response) => {
				var responseData = response.data.data;
				this.overview = responseData;
				responseData.projects.forEach((item) => {
					this.projects.push(item.project_title)
				})
			})
			.catch((e) => {
				if (e.response.status === 401) {
					/* auth error, redirect user to login page */
					this.$store.commit("unsetUserData");
					this.$router.push("/login");
				}
			})
			.finally(() => {
				this.loading.overview = false;
				this.$root.$emit('panelContainerLoading', false);
			});
	},
	destroyed() {
		this.$root.$emit('panelContainerLoading', false);
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Overview");
	},
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables";

.overview {
	.project-details {
		padding-top: 64px;

		@include only-phone-bs-lg {
			padding-top: 32px;
		}
	}

	.recent-activities {
		li {
			padding: 16px 0;
			border-bottom: 1px solid #f2f2f5;
			font-size: 16px;
			line-height: 26px;
			letter-spacing: 0.2px;
			color: #252733;

			&.not-read {
				span {
					vertical-align: middle;
				}

				&::after {
					content: "";
					display: inline-block;
					width: 10px;
					height: 10px;
					background: #0063f7;
					border-radius: 50%;
					margin-left: 12px;
					vertical-align: middle;
				}
			}

			&:last-child {
				border: none;
			}
		}
	}

	.recently-files {
		.recently-files-content-wrapper {
			display: flex;
			flex-direction: column;
			height: 100%;
			margin: -8px;

			.content {
				flex: 1;
				display: flex;
				flex-wrap: wrap;

				.file-item {
					width: max-content;
					padding: 8px;
				}
			}
		}

		.recently-files-footer {
			text-align: center;
			padding-top: 18px;

			.view-all-btn {
				font-weight: 600;
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 0.2px;
				background: none;
				color: #0063f7;
			}
		}
	}
}
</style>
