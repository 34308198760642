<template>
	<div class="xf-btn-wrapper">
		<button :class="[btnstyle, block ? 'block' : '', compact ? 'compact' : '',]" :disabled="disabled" @click="$emit('click' , $event)">
			<xf-loading class="btn-loading" v-if="loading" :color="loadingColor" :size="20" :thickness="2" />
			<span :class="{ hide: loading }" class="btn-content">
				<slot></slot>
			</span>
		</button>
	</div>
</template>

<script>
export default {
	props: {
		btnstyle: {
			default: "primary",
			type: String,
		},
		block: {
			default: false,
			type: Boolean,
		},
		compact: {
			default: false,
			type: Boolean,
		},
		disabled: {
			default: false,
			type: Boolean,
		},
		loading: {
			default: false,
			type: Boolean,
		},
	},
	computed: {
		loadingColor() {
			if (this.btnstyle == "secondary") return "#0063f7";
			return "#fff"
		}
	}
}
</script>

<style lang="scss" scoped>
.xf-btn-wrapper {
	button {
		height: 45px;
		padding: 0 24px;
		border-radius: 6px;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.2px;
		display: block;
		transition: all 200ms ease;
		background: transparent;
		position: relative;

		.btn-loading {
			position: absolute;
			top: 50%;
			left: 50%;

			transform: translate(-50%, -50%);
		}

		.btn-icon {
			margin-right: 12px;
			font-size: 10px;
		}

		.btn-content {
			&.hide {
				visibility: hidden;
			}
		}

		&.compact {
			padding: 14px 12px;
		}

		&.block {
			width: 100%;
		}

		&.primary {
			background: #0063f7;
			color: #ffffff;

			&:hover {
				background: #5b8def;
			}

			&:disabled {
				background: #8f90a6;
			}
		}

		&.secondary {
			color: #1c1c28;
			background: #f4f6f3;
			padding: 14px 12px;

			&:hover {
				background: #f4f6f3;
			}

			&:disabled {
				background: #f4f6f3;
				color: #8f90a6;
			}
		}

		&.danger {
			background: #ff3b3b;
			color: #ffffff;

			&:hover {
				background: #ff5c5c;
			}

			&:disabled {
				background: #8f90a6;
			}
		}

		&.success {
			background: #06c270;
			color: #ffffff;

			&:hover {
				background: #58bb90;
			}

			&:disabled {
				background: #8f90a6;
			}
		}

		&.outline {
			border: 1px solid #0063f7;
			color: #0063f7;

			&:hover {
				border-color: #5b8def;
				color: #5b8def;
			}

			&:disabled {
				border-color: #8f90a6;
				color: #8f90a6;
			}
		}
	}
}
</style>