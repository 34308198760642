<template>
	<transition name="fade">
		<div class="xf-alert" v-if="show">
			<div class="xf-alert-content-wrapper">
				<div class="xf-alert-content">
					<div class="xf-alert-title">Your Password has been created</div>
					<div class="xf-alert-text">Now continue to the login page</div>
					<div class="xf-alert-btns">
						<XfBtn text="Continue" @click="onContinue"/>
					</div>
				</div>
			</div>
			<div class="xf-alert-back" @click="show=false"></div>
		</div>
	</transition>
</template>

<script>
import XfBtn from '../btns/XfBtn.vue'
export default {
	data() {
		return {
			show: false,
		}
	},
	components: {
		XfBtn
	},
	methods:{
		onContinue(){
			this.show=false;
			this.$emit('onContinue');
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/_variables";

.xf-alert {
	.xf-alert-content-wrapper {
		padding: 0 16px;
		width: 100%;
		max-width: 578px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;

		.xf-alert-content {
			width: 100%;
			background-color: #fff;
			padding: 32px;
			border-radius: 12px;
			max-height: 90vh;
			overflow-y: auto;

			.xf-alert-title {
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.2px;
				color: #1c1c28;
				margin-bottom: 24px;

				@include only-phone {
					font-size: 18px;
					line-height: 22px;
					margin-bottom: 20px;
				}
			}

			.xf-alert-text {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.2px;
				color: #8f90a6;
				margin-bottom: 32px;

				@include only-phone {
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 50px;
				}
			}
		}
	}

	.xf-alert-back {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #0005;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>