import Vue from 'vue'
import VueRouter from 'vue-router'
import Panel from '../views/panel/Panel'

/* login */
import Login from '../views/login/Login'
import CreatePassword from '../views/login/CreatePassword'

/* panel pages */
import Overview from '../views/panel/overview/Overview'
import Documents from '../views/panel/documents/Documents'
import Support from '../views/panel/support/Support'
import Settings from '../views/panel/settings/Settings'
import Subscription from '../views/panel/subscription/Subscription'
import Notifications from '../views/panel/notifications/Notifications'
import Logout from '../views/panel/Logout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/create-password/:hash',
    name: 'CreatePassword',
    component: CreatePassword,
  },
  {
    path: '/panel',
    component: Panel,
    redirect: '/panel/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'documents',
        name: 'Documents',
        component: Documents
      },
      {
        path: 'support',
        name: 'Support',
        component: Support
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: Subscription
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications
      },
      {
        path: 'logout',
        name: 'Logout',
        component: Logout
      },
      {
        path: '*',
        redirect: '/404'
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
