import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './components'

/* global */
import global from './global'
Vue.prototype.$global = global;

/* axios */
import axios from 'axios'
axios.defaults.baseURL = global.api_base_url;
Vue.prototype.$http = axios

/* toast */
import Toast from "vue-toastification";
Vue.use(Toast);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

