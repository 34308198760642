<template>
	<div class="xf-profile-avatar">
		<img :src="img" v-if="img != null">
		<i class="fas fa-user" v-else></i>
		<XfLoading v-if="loading" class="profile-loading" color="#ffffff" :thickness="3" :size="25" />
	</div>
</template>

<script>
import XfLoading from "./XfLoading"
export default {
	components: {
		XfLoading
	},
	props: {
		img: {
			default: null,
		},
		loading: {
			default: false,
		}
	}
}
</script>

<style lang="scss" scoped>
.xf-profile-avatar {
	width: 48px;
	height: 48px;
	background-color: #e9e9ea;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;

	img {
		width: 100%;
		height: 100%;
	}

	i {
		font-size: 23px;
		color: #000;
	}

	.profile-loading {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>