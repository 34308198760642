<template>
	<xf-modal class="twoFA" v-model="$parent.twoFA.show" @hide="hideEvent">
		<div class="twoFA-loading" v-if="$parent.twoFA.loading">
			<xf-loading />
		</div>

		<div v-else>
			<div class="twoFA-header">
				<button class="close-btn" @click="$parent.twoFA.show = false">
					<i class="fas fa-times"></i>
				</button>
				<div class="twoFA-title" v-if="$parent.twoFA.step == 'scanCode'">Scan the QR code</div>
				<div class="twoFA-title" v-if="$parent.twoFA.step == 'viewCode'">Can’t scan the QR code?</div>
				<div class="twoFA-title" v-if="$parent.twoFA.step == 'enterAuthenticationCode'">Enter the authentication code</div>
			</div>

			<!-- scan QR code -->
			<div class="twoFA-content" v-if="$parent.twoFA.step =='scanCode'">
				<div class="twoFA-desc">
					Scan the QR code below using an authentication application on your phone to enabaling the two-factor authentication If you dont' have an authentication app on your phone you need to install one like Google Authenticator.
				</div>

				<div class="qr-code">
					<img :src="$parent.twoFA.data.qr_image">
				</div>

				<button class="twoFA-text-btn cant-scan-btn" @click="$parent.twoFA.step = 'viewCode'">
					Can’t scan the QR code?
				</button>

				<xf-btn class="twoFA-next-btn" @click="$parent.twoFA.step = 'enterAuthenticationCode'">
					Next
				</xf-btn>
			</div>

			<!-- view QR code -->
			<div class="twoFA-content" v-if="$parent.twoFA.step =='viewCode'">
				<div class="twoFA-desc">
					Scan the QR code below using an authentication application on your phone to enabaling the two-factor authentication If you dont' have an authentication app on your phone you need to install one like Google Authenticator.
				</div>

				<div class="twoFA-code">
					{{$parent.twoFA.data.qr_code}}
				</div>

				<button class="twoFA-text-btn want-scan-btn" @click="$parent.twoFA.step = 'scanCode'">
					Try scan the QR code again?
				</button>

				<xf-btn class="twoFA-next-btn" @click="$parent.twoFA.step = 'enterAuthenticationCode'">
					Next
				</xf-btn>
			</div>

			<!-- enter authentication code -->
			<div class="twoFA-content" v-if="$parent.twoFA.step =='enterAuthenticationCode'">
				<div class="twoFA-desc">
					Enter the code that you recived from the authentication app below to complete the verification process. If you dont' have an authentication app on your phone you need to install one like Google Authenticator , etc.
				</div>

				<form class="twoFA-form" @submit.prevent="verifyAuthCode">
					<xf-input label="Authentication code" id="auth-code-input" v-model="$parent.twoFA.ga_code" />
					<xf-btn class="twoFA-verify-btn" :loading=loading.authCodeVerify>
						Verify
					</xf-btn>
				</form>
			</div>
		</div>
	</xf-modal>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				authCodeVerify: false,
			}
		}
	},
	methods: {
		hideEvent() {
			if (this.$parent.twoFA.enabled) { this.$parent.account_settings.twoFA = true; }
			else { this.$parent.account_settings.twoFA = false; }
		},
		verifyAuthCode() {
			this.loading.authCodeVerify = true;

			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			}

			var data = {
				ga_code: this.$parent.twoFA.ga_code
			}

			this.$http.post('2fa', data, config)
				.then((/* response */) => {
                    this.$parent.twoFA.enabled = true;
					this.$toast.success("Two-factor authentication successfully enabled!");
					//var responseData = response.data.data;
					this.$store.commit("setUserData", {
						persist: this.$store.state.User.data_cached,
						userData: {
							twoFA: true,
						}
					})
                    this.$parent.twoFA.show = false;
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error("Can't verify the code!");
					}
				})
				.finally(() => {
					this.loading.authCodeVerify = false;
				});
		}
	}
}
</script>

<style lang="scss">
.twoFA {
	.twoFA-loading {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.twoFA-header {
		display: flex;
		align-items: center;

		.close-btn {
			background: none;
			margin-right: 32px;

			i {
				font-size: 16px;
			}
		}

		.twoFA-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}
	}

	.twoFA-content {
		margin-top: 24px;

		.twoFA-desc {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #8f90a6;
		}

		.qr-code {
			margin-top: 32px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 210px;
				height: 210px;
			}
		}

		.twoFA-code {
			margin-top: 32px;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}

		.twoFA-form {
			margin-top: 28px;
			width: 100%;
			max-width: 450px;
		}

		.twoFA-text-btn {
			background: none;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.2px;
			color: #0063f7;

			&.cant-scan-btn {
				display: block;
				margin: 0 auto;
				margin-top: 20px;
			}

			&.want-scan-btn {
				margin-top: 20px;
			}
		}

		.twoFA-next-btn {
			margin-top: 28px;
			display: flex;
			justify-content: flex-end;
		}

		.twoFA-verify-btn {
			margin-top: 28px;
		}
	}
}
</style>