<template>
	<div class="settings">
		<xf-tabs :items="settingsTab" v-model="currentTab"></xf-tabs>

		<div class="settings-tab-content">
			<AccountSettings v-if="currentTab == 0"/>
			<TeamMembers v-if="currentTab == 1"/>
		</div>

	</div>
</template>

<script>
import AccountSettings from "./AccountSettings"
import TeamMembers from "./TeamMembers"

export default {
	data() {
		return {
			settingsTab: [
				"Account settings",
				"Team members"
			],
			currentTab: 0,
		}
	},
	components: {
		AccountSettings,
		TeamMembers,
	},
	destroyed() {
		this.$root.$emit('panelContainerLoading', false);
	},
	mounted() {
		this.$root.$emit('setPanelTitleText' , "Settings");
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables";

.settings {
	.settings-tab-content {
		padding-top: 64px;

		@include only-phone-bs-lg{
			padding-top: 32px;
		}
	}
}
</style>