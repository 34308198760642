export default {
	state: {
		data: {
			active_projects: []
		}
	},
	mutations: {
		setActiveProjects(state, payload) {
			state.data.active_projects = payload;
		}
	},
	getters: {
		activeProjectsCount(state){
			return state.data.active_projects.length;
		}
	},
	actions: {
	},
};
