<template>
	<div class="support" v-if="!loading.tickets">
		<div class="row my-gutters">
			<div class="col tickets-col" :class="[(isChatOpen) ? 'chat-open' : '']">
				<xf-section-card class="tickets-list-card" title="Tickets Lists">
					<template v-slot:header-right>
						<xf-btn :compact="true" @click="toggleNewTicketModal">
							<i class="fas fa-plus btn-icon"></i> Create New Ticket
						</xf-btn>
					</template>

					<div class="tickets-list">
						<table class="tickets-list-table">
							<thead>
								<tr>
									<th>Title</th>
									<th>Number</th>
									<th>Project</th>
									<th>Subject</th>
									<th>Date</th>
									<th class="min-width">Status</th>
								</tr>
							</thead>
							<tbody>

								<tr class="ticket-list-row" v-for="(item, index) in tickets" :class="[(index == selectedChatIndex) ? 'active' : '']" :key="index" @click="selectChat(index)">
									<td>{{item.ticket_title}}</td>
									<td>#{{item.ticket_id}}</td>
									<td>{{item.ticket_project}}</td>
									<td>{{item.ticket_subject}}</td>
									<td>{{item.updated_at}}</td>
									<td>
										<xf-badge badgeStyle="secondary" v-if="item.ticket_status == 'resolved'">Resolved</xf-badge>
										<xf-badge badgeStyle="primary" v-else-if="item.ticket_status == 'answered'">Answered</xf-badge>
										<xf-badge badgeStyle="warning" v-else-if="item.ticket_status == 'waiting'">Waiting</xf-badge>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</xf-section-card>
			</div>

			<div class="col chat-col" v-if="isChatOpen">
				<div class="chat-loading" v-if="loading.chat">
					<xf-loading />
				</div>

				<xf-chat v-else>
					<xf-chat-header>
						<div class="xf-chat-title">{{selectedChatData.ticket_title}}</div>
						<div class="xf-chat-header-right">
							<xf-btn btnstyle="secondary" v-if="selectedChatData.ticket_status != 'resolved'" @click="resolveTicket" :loading="loading.resolveTicket">Resolve</xf-btn>
							<xf-btn btnstyle="secondary" :disabled="true" v-else>Resolved</xf-btn>
						</div>
					</xf-chat-header>

					<xf-chat-messages>
						<div class="xf-chat-message" v-for="(item , index) in selectedChatData.messages" :key="index" :class="{right: item.sender_role != 'support'}">
							<div class="xf-chat-message-content">
								<div class="xf-message-sender">
									<span v-if="item.sender_role == 'support'">{{item.sender_name}}, Support team</span>
									<span v-else>{{item.sender_name}}</span>
								</div>
								<div class="xf-message-text">
									{{item.ticket_content}}

									<div class="ticket-files" v-if="item.ticket_files.length > 0">
										<div class="ticket-file" v-for="(file , index) in item.ticket_files" :key="index" @click="openUrl(file.attachment_url)">
											<div class="file-icon-wrapper">
												<i class="fas fa-file"></i>
											</div>
											<div class="file-title">
												<span>{{file.attachment_filename}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="xf-chat-message-date">{{item.created_at}}</div>
						</div>
					</xf-chat-messages>

					<xf-chat-footer>
						<div class="xf-chat-message-input">
							<textarea class="xf-chat-input" placeholder="Type your answer" v-model="chat.replyText"></textarea>
							<button class="xf-chat-attach-btn" @click.prevent="$refs.chat_file_input.click()">
								<i class="fas fa-paperclip"></i>
								<span v-if="chat.file != null">{{chat.file.name}}</span>
								<span v-else>Attach File</span>
							</button>
							<input class="d-none" ref="chat_file_input" type="file" @change="handleChatFileUpload" />
						</div>
						<div class="xf-chat-footer-right">
							<xf-btn class="xf-chat-send-btn" @click="replyTicket" :loading="loading.replyTicket" :disabled="this.chat.replyText == ''">
								<i class="fas fa-paper-plane"></i>
								Send
							</xf-btn>
						</div>
					</xf-chat-footer>
				</xf-chat>
			</div>
		</div>

		<xf-modal class="new-ticket" v-model="showNewTicketModal" ref="childComponent">
			<div class="new-ticket-loading" v-if="loadingNewTicketModal">
				<xf-loading />
			</div>

			<div v-else>
				<div class="new-ticket-header">
					<button class="close-btn" @click="showNewTicketModal=false">
						<i class="fas fa-times"></i>
					</button>
					<div class="new-ticket-title">New Ticket</div>
				</div>

				<div class="new-ticket-content">
					<form class="new-ticket-form" @submit.prevent>
						<div class="form-row">
							<xf-input label="Title" id="new-ticket-title" v-model="newTicket.title" />
						</div>
						<div class="form-row">
							<xf-select-input label="Subject" placeholder="Select Subject" :selectOptions="newTicket.subjects" v-model="newTicket.subject" />
						</div>
						<div class="form-row">
							<xf-select-input label="Project" placeholder="Select Project" :selectOptions="newTicket.projects" v-model="newTicket.project" />
						</div>
						<div class="form-row">
							<xf-textarea label="Message" id="new-ticket-desc" placeholder="Explain the problem" v-model="newTicket.message" />
						</div>
						<div class="form-row">
							<xf-btn class="new-ticket-submit-btn" btnstyle="secondary" @click.prevent="$refs.new_ticket_file_input.click()">
								<i class="fas fa-plus btn-icon"></i> Attach Files
							</xf-btn>
							<input class="d-none" ref="new_ticket_file_input" type="file" @change="handleNewTicketFileUpload" />
						</div>
						<div class="form-row">
							<xf-upload-loading class="new-ticket-file" v-for="(item , index) in newTicket.assets" :key="index" :title="item.name" :value="item.percentCompleted" @delete="handleNewTicketFileDelete(index)" />
						</div>
						<div class="form-btns d-flex">
							<xf-btn class="new-ticket-submit-btn" @click.prevent="submitNewTicket" :disabled="!isNewTicketDataFilled" :loading="loading.new_ticket_submit">
								Submit
							</xf-btn>
							<xf-btn class="new-ticket-cancel-btn" btnstyle="outline" @click.prevent="showNewTicketModal = false">
								Cancel
							</xf-btn>
						</div>
					</form>
				</div>
			</div>
		</xf-modal>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				tickets: false,
				chat: false,
				new_ticket_projects: false,
				new_ticket_subjects: false,
				new_ticket_submit: false,
				replyTicket: false,
				resolveTicket: false,
			},
			isChatOpen: false,
			tickets: [],
			selectedChatIndex: null,
			selectedChatData: null,
			showNewTicketModal: false,
			newTicket: {
				projects: [],
				subjects: [],
				title: "",
				subject: "",
				project: "",
				message: "",
				assets: [],
			},
			chat: {
				replyText: "",
				file: null
			}
		}
	},
	computed: {
		selectedChat() {
			return this.tickets[this.selectedChatIndex];
		},
		loadingNewTicketModal() {
			if (this.loading.new_ticket_projects || this.loading.new_ticket_subjects) return true;
			return false;
		},
		isNewTicketDataFilled() {
			if (this.newTicket.title == "" || this.newTicket.subject == "" || this.newTicket.project == "" || this.newTicket.message == "" || this.newTicket.message == "") return false;
			return true
		}
	},
	methods: {
		openUrl(url) {
			window.open(url, "_blank");
		},
		loadTickets() {
			/* set loading */
			this.loading.tickets = true;
			this.$root.$emit('panelContainerLoading', true);

			/* call the api */
			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			this.$http.get('ticket', config)
				.then((response) => {
					var responseData = response.data.data;
					this.tickets = responseData;
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					}
				})
				.finally(() => {
					this.loading.tickets = false;
					this.$root.$emit('panelContainerLoading', false);
				});
		},
		selectChat(index) {
			this.isChatOpen = true;
			this.selectedChatIndex = index;
			this.chat.replyText = ""; //reset reply text
			this.chat.file = null; //reset reply attachment
			this.$root.$emit('mobileBackBtnShow', true);

			/* get chat data */
			/* set loading */
			this.loading.chat = true;

			/* call the api */
			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			this.$http.get('ticket/' + this.selectedChat.ticket_id, config)
				.then((response) => {
					var responseData = response.data.data;
					this.selectedChatData = responseData;
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					}
				})
				.finally(() => {
					this.loading.chat = false;
					this.$root.$emit('panelContainerLoading', false);
				});
		},

		/* ------------------------------- new ticket ------------------------------- */
		toggleNewTicketModal() {
			this.showNewTicketModal = true;

			/* reset data */
			this.newTicket.subjects = [];
			this.newTicket.projects = [];
			this.newTicket.subject = "";
			this.newTicket.project = "";

			/* get subjects */
			this.loading.new_ticket_subjects = true;

			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			this.$http.get('ticket/subjects', config)
				.then((response) => {
					var responseData = response.data.data;
					responseData.forEach((item) => {
						this.newTicket.subjects.push({
							title: item,
							value: item
						});
					})
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					}
				})
				.finally(() => {
					this.loading.new_ticket_subjects = false;
					this.$root.$emit('panelContainerLoading', false);
				});

			/* get projects */
			this.loading.new_ticket_projects = true;

			this.$http.get('project', config)
				.then((response) => {
					var responseData = response.data.data;
					responseData.forEach((item) => {
						this.newTicket.projects.push({
							title: item.project_title,
							project_id: item.project_id
						});
					})
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					}
				})
				.finally(() => {
					this.loading.new_ticket_projects = false;
					this.$root.$emit('panelContainerLoading', false);
				});
		},
		handleNewTicketFileUpload(e) {
			var file = e.target.files[0];

			var fileIndex = this.newTicket.assets.push({
				name: file.name,
				size: file.size,
				type: file.type,
				finished: false,
				percentCompleted: 0,
				attachment_id: null,
			}) - 1;

			var data = new FormData();
			data.append('relation_id', '1');
			data.append('title', 'test');
			data.append('category', 'assets');
			data.append('file_asset', file);

			var config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` },
				onUploadProgress: (progressEvent) => {
					var percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
					this.newTicket.assets[fileIndex].percentCompleted = percentCompleted;
				}
			};

			this.$http.post('https://xapi.learneet.ir/assets/new', data, config)
				.then((response) => {
					var responseData = response.data.data;
					this.newTicket.assets[fileIndex].attachment_id = responseData.attachment_id;
					this.newTicket.assets[fileIndex].finished = true;
				})
				.catch((e) => {
					/* remove the file from ticket */
					this.newTicket.assets.splice(fileIndex, 1);

					/* show proper error */
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error("Can't upload the file!");
					}
				});
		},
		handleNewTicketFileDelete(index) {
			this.newTicket.assets.splice(index, 1);
		},
		submitNewTicket() {
			/* set loading */
			this.loading.new_ticket_submit = true;

			/* call the api */
			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			var data = {
				title: this.newTicket.title,
				subject: this.newTicket.subject.value,
				project: this.newTicket.project.project_id,
				content: this.newTicket.message,
				files: []
			};

			/* add files */
			this.newTicket.assets.forEach((item) => {
				if (item.finished && item.attachment_id != null) data.files.push(item.attachment_id);
			})

			this.$http.post('ticket/new', data, config)
				.then((response) => {
					var responseData = response.data.data;
					this.tickets = responseData;
					this.showNewTicketModal = false;
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error("Can't create a new ticket!");
					}
				})
				.finally(() => {
					this.loading.new_ticket_submit = false;
				});
		},

		/* ---------------------------------- chat ---------------------------------- */
		handleChatFileUpload(e) {
			var file = e.target.files[0];
			this.chat.file = file;
		},
		replyTicket() {
			/* set loading */
			this.loading.replyTicket = true;

			/* call the api */
			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			if (this.chat.file == null) {
				var data = {
					content: this.chat.replyText,
				};

				this.$http.post('ticket/reply/' + this.selectedChat.ticket_id, data, config)
					.then((response) => {
						var responseData = response.data.data;
						this.chat.replyText = ""; //reset reply text
						this.chat.file = null; //reset reply attachment
						this.selectedChatData.messages.push(responseData); //add new message to messages list
						this.tickets[this.selectedChatIndex].ticket_status = "waiting"; //set ticket status to "waiting"
						this.selectedChatData.ticket_status = "waiting"; //set ticket status to "waiting"
					})
					.catch((e) => {
						if (e.response.status === 401) {
							/* auth error, redirect user to login page */
							this.$store.commit("unsetUserData");
							this.$router.push("/login");
						} else {
							this.$toast.error("Can't reply the ticket!");
						}
					})
					.finally(() => {
						this.loading.replyTicket = false;
					});
			} else {
				data = new FormData();
				data.append('relation_id', this.selectedChat.ticket_id);
				data.append('title', this.selectedChat.ticket_title);
				data.append('category', 'assets');
				data.append('file_asset', this.chat.file);

				this.$http.post('https://xapi.learneet.ir/assets/new', data, config)
					.then((response) => {
						var responseData = response.data.data;
						data = {
							content: this.chat.replyText,
							files: [responseData.attachment_id]
						}

						this.$http.post('ticket/reply/' + this.selectedChat.ticket_id, data, config)
							.then((response) => {
								var responseData = response.data.data;
								this.chat.replyText = ""; //reset reply text
								this.chat.file = null; //reset reply attachment
								this.selectedChatData.messages.push(responseData); //add new message to messages list
								this.tickets[this.selectedChatIndex].ticket_status = "waiting"; //set ticket status to "waiting"
								this.selectedChatData.ticket_status = "waiting"; //set ticket status to "waiting"
							})
							.catch((e) => {
								if (e.response.status === 401) {
									/* auth error, redirect user to login page */
									this.$store.commit("unsetUserData");
									this.$router.push("/login");
								} else {
									this.$toast.error("Can't reply the ticket!");
								}
							})
							.finally(() => {
								this.loading.replyTicket = false;
							});
					})
					.catch((e) => {
						if (e.response.status === 401) {
							/* auth error, redirect user to login page */
							this.$store.commit("unsetUserData");
							this.$router.push("/login");
						} else {
							this.$toast.error("Can't reply the ticket!");
						}
					})
					.finally(() => {
						this.loading.replyTicket = false;
					});
			}
		},
		resolveTicket() {
			/* set loading */
			this.loading.resolveTicket = true;

			/* call the api */
			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			};

			var data = {}

			this.$http.put('ticket/resolve/' + this.selectedChat.ticket_id, data, config)
				.then((response) => {
					var responseData = response.data.data;
					this.tickets[this.selectedChatIndex].ticket_status = responseData.ticket_status;
					this.selectedChatData.ticket_status = responseData.ticket_status;
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error("Can't resolve the ticket!");
					}
				})
				.finally(() => {
					this.loading.resolveTicket = false;
				});
		}
	},
	created() {
		this.$root.$on('mobileBackBtnClick', () => {
			this.isChatOpen = false;
			this.selectedChatIndex = null;
			this.selectedChatData = null;
			this.$root.$emit('mobileBackBtnShow', false);
		});

		/* load tickets */
		this.loadTickets();
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Tickets");
	},
	destroyed() {
		this.$root.$emit('mobileBackBtnShow', false);
	}
}
</script>

<style lang="scss">
@import "../../../assets/styles/_variables";

.support {
	height: 100%;

	.row {
		height: 100%;

		.col {
			height: 100%;

			&.tickets-col {
				flex: 0 0 auto;
				width: 100%;

				&.chat-open {
					width: 50%;

					@include only-phone-bs-lg {
						display: none;
					}
				}
			}

			&.chat-col {
				flex: 0 0 auto;
				width: 50%;

				@include only-phone-bs-lg {
					width: 100%;
				}

				.chat-loading {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #ffffff;
					border-radius: 12px;
					padding: 32px;
				}
			}
		}
	}

	.tickets-list-card {
		height: 100%;

		.xf-section-card-header {
			margin-bottom: 55px !important;
		}

		.tickets-list {
			margin: 0 -32px;
			margin-top: -32px;

			.tickets-list-table {
				border-collapse: separate;
				width: 100%;
				max-width: 100%;
				border-spacing: 0 16px;
				padding: 0 32px;

				.min-width {
					width: 1%;
					white-space: nowrap;
				}

				thead {
					position: relative;
					top: 16px;
				}

				th {
					padding: 13px 16px;
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;
					letter-spacing: 0.2px;
					color: #8f90a6;
				}

				.ticket-list-row {
					margin-bottom: 16px;
					cursor: pointer;

					&:hover td {
						background-color: #fcfcfc;
					}

					&.active td {
						background-color: #0063f7;
						color: #ffffff;
					}

					td {
						padding: 13px 16px;
						border-top: 1px solid #c7c9d9;
						border-bottom: 1px solid #c7c9d9;
						vertical-align: middle;
						white-space: nowrap;

						&:first-child {
							border-left: 1px solid #c7c9d9;
							border-top-left-radius: 8px;
							border-bottom-left-radius: 8px;
						}
						&:last-child {
							border-right: 1px solid #c7c9d9;
							border-top-right-radius: 8px;
							border-bottom-right-radius: 8px;
						}
					}
				}
			}
		}
	}

	.new-ticket {
		.new-ticket-loading {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.new-ticket-header {
			display: flex;
			align-items: center;

			.close-btn {
				background: none;
				margin-right: 32px;

				i {
					font-size: 16px;
				}
			}

			.new-ticket-title {
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.2px;
				color: #1c1c28;
			}
		}

		.new-ticket-content {
			margin-top: 24px;

			.new-ticket-form {
				margin-top: 28px;
				width: 100%;
				max-width: 450px;

				.form-row {
					margin-bottom: 16px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.form-btns {
					margin-top: 32px;
				}

				.new-ticket-send-btn {
					margin-top: 28px;
				}

				.new-ticket-cancel-btn {
					margin-left: 20px;
				}

				.new-ticket-file:not(:last-child) {
					margin-bottom: 10px;
				}
			}
		}
	}
}

.xf-chat-send-btn {
	button {
		padding: 16px 12px !important;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.2px;
		height: auto !important;

		i {
			margin-right: 12px;
			font-size: 10px !important;
		}
	}
}

.ticket-files {
	margin-top: 16px;
	.ticket-file {
		display: flex;
		align-items: center;
		border: 1px solid #fff;
		border-radius: 9px;
		padding: 10px;
		cursor: pointer;
		margin-bottom: 7px;
		&:last-child {
			margin-bottom: 0;
		}
		.file-icon-wrapper {
			width: 45px;
			height: 45px;
			background: #ffffff;
			border-radius: 9px;
			display: flex;
			justify-content: center;
			align-items: center;
			i {
				color: #000000;
				font-size: 24px;
			}
		}
		.file-title {
			margin-left: 12px;
		}
	}
}
</style>