<template>
	<div class="role-selector">
		<div class="role-selector-btn" @click="$emit('click' , $event)">
			<span class="current-role" v-if="role == 'member'">Member</span>
			<span class="current-role" v-else-if="role == 'user'">Admin</span>
			<div class="btn-icon"><i class="fas fa-chevron-down"></i></div>
		</div>

		<div class="role-selector-content" :class="{open: open}">
			<ul class="role-selector-items">
				<li :class="{active: role == 'member'}" @click="$emit('changeRole' , 'member')">
					<div class="selected-icon"><i class="fas fa-check"></i></div>
					<div class="item-text">Member</div>
				</li>
				<li :class="{active: role == 'user'}" @click="$emit('changeRole' , 'user')">
					<div class="selected-icon"><i class="fas fa-check"></i></div>
					<div class="item-text">Admin</div>
				</li>
				<div class="divider"></div>
				<li @click="$emit('remove')">
					<div class="selected-icon"><i class="fas fa-check"></i></div>
					<div class="item-text alert">Remove</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		open: {
			default: false,
		},
		role: {
			default: "",
		}
	}
}
</script>

<style lang="scss" scoped>
.role-selector {
	width: max-content;

	.role-selector-btn {
		display: flex;
		align-items: center;
		cursor: pointer;

		.current-role {
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			text-align: right;
			letter-spacing: 0.2px;
			color: #1c1c28;
		}

		.btn-icon {
			margin-left: 6px;
		}
	}

	&.open .role-selector-content {
		display: block;
	}

	.role-selector-content {
		background: #ffffff;
		border: 1px solid #c7c9d9;
		box-sizing: border-box;
		border-radius: 8px;
		position: absolute;
		z-index: 5;
		top: 100%;
		right: 0;
		margin-top: 20px;
		overflow: hidden;
		display: none;

		.role-selector-items {
			max-width: 300px;
			width: max-content;

			li {
				padding: 16px 24px;
				display: flex;
				align-items: center;
				cursor: pointer;

				&:hover {
					background: #eee;
				}

				.selected-icon {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex: 0 0 auto;
					width: 20px;
					visibility: hidden;

					i {
						font-size: 10px;
					}
				}

				&.active {
					.selected-icon {
						visibility: visible;
					}
				}

				.item-text {
					&.alert {
						color: #ff3b3b;
					}
				}
			}

			.divider {
				width: 100%;
				height: 1px;
				background-color: #c7c9d9;
			}
		}
	}
}
</style>