<template>
	<span class="xf-badge" :class="badgeStyle">
		<slot></slot>
	</span>
</template>

<script>
export default {
	props: {
		badgeStyle: {
			default: "primary",
			type: String,
		},
	}
}
</script>

<style lang="scss" scoped>
.xf-badge {
	display: block;
	width: max-content;
	padding: 12px 20px;
	border-radius: 6px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.2px;

	&.primary {
		background: #f2f5ff;
		color: #0063F7;
	}

	&.success {
		background: #DAF6EA;
		color: #06C270;
	}

	&.warning {
		background: #FFEDD9;
		color: #ff8800;
	}

	&.secondary {
		background: #E8E8E9;
		color: #262631;
	}
}
</style>