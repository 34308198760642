<template>
	<div class="panel-wrapper">
		<div class="panel-loading" v-if="loading.panel" key="panel_loading">
			<xf-loading :size="64" />
		</div>

		<div class="panel" key="panel" v-else>
			<xf-sidebar />

			<div class="panel-container">
				<div class="panel-container-loading" :class="[(loading.container) ? 'show' : '']">
					<xf-loading :withBg="true" />
				</div>

				<div class="panel-container-header">
					<button class="mobile-open-sidebar" @click="openMobileSidebar"><i class="fas fa-bars"></i></button>
					<button class="mobile-back-btn" v-if="showMobileBackBtn" @click="$root.$emit('mobileBackBtnClick')"><i class="fas fa-chevron-left"></i></button>
					<div class="panel-container-header-title">{{panelTitleText}}</div>
					<div class="panel-container-header-right">
						<xf-notification />
					</div>
				</div>

				<div class="panel-content">
					<transition name="fade" mode="out-in">
						<router-view></router-view>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				panel: false,
				container: false,
			},
			panelTitleText: "",
			showMobileBackBtn: false,
		}
	},
	methods: {
		openMobileSidebar() {
			document.querySelector(".xf-sidebar").classList.add("show");
		}
	},
	created() {
		/* check if token is valid */
		if (!this.$store.getters.isUserLoggedIn) {
			this.$router.push("/login");
		} else if (!this.$store.state.User.data_validated) {
			/* validate user data */
			this.loading.panel = true;
			this.$store.dispatch("validateUserData")
				.catch(() => {
					this.$store.commit("unsetUserData");
					this.$router.push("/login");
				})
				.finally(() => {
					this.loading.panel = false;
				});
		}

		/* listners */
		this.$root.$on('setPanelTitleText', (new_text) => {
			this.panelTitleText = new_text;
		});
		this.$root.$on('mobileBackBtnShow', (show = true) => {
			this.showMobileBackBtn = show;
		});
		this.$root.$on('panelContainerLoading', (loading = true) => {
			this.loading.container = loading;
		});
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.panel-wrapper {
	height: 100%;

	.panel-loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.panel {
		height: 100%;
		padding-left: 255px;
		transition: padding-left 200ms ease;

		@include only-phone {
			padding-left: 0px;
		}

		.panel-container {
			width: 100%;
			height: 100%;
			background-color: #e5e5e5;
			z-index: 1;
			position: relative;
			display: flex;
			flex-direction: column;

			.panel-container-loading {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: #0006;
				z-index: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;
				transition: opacity 200ms ease;
				pointer-events: none;

				&.show{
					pointer-events: initial;
					opacity: 1;
				}
			}

			.panel-container-header {
				display: flex;
				align-items: center;
				padding: 30px;

				@include only-phone-bs-lg {
					padding-left: 16px;
					padding-right: 16px;
				}

				.mobile-open-sidebar {
					background: none;
					width: 24px;
					height: 24px;
					margin-right: 32px;
					display: none;

					@include only-phone {
						display: block;
					}

					i {
						font-size: 24px;
					}
				}

				.mobile-back-btn {
					background: none;
					width: 24px;
					height: 24px;
					margin-right: 12px;
					display: none;

					@include only-phone {
						display: block;
					}

					i {
						font-size: 15px;
					}
				}

				.panel-container-header-title {
					font-weight: bold;
					font-size: 24px;
					line-height: 29px;
					letter-spacing: 0.2px;
					color: #1c1c28;

					@include only-phone-bs-lg {
						font-size: 18px;
						line-height: 22px;
					}
				}

				.panel-container-header-right {
					margin-left: auto;
				}
			}

			.panel-content {
				flex: 1;
				padding: 34px 30px 64px 30px;
				overflow-y: auto;

				@include only-phone-bs-lg {
					padding: 10px 16px 40px 16px;
				}
			}
		}
	}
}
</style>