<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	created() {
		/* get data from local storage (if set) */
		this.$store.dispatch("initUserData");
	}
}
</script>

<style lang="scss">
/* reboot */
@import "./assets/styles/reboot";

/* essential things */
@import "./assets/styles/bootstrap_grid";
@import "./assets/styles/variables";
@import "./assets/styles/global";

/* toastification */
@import "./assets/styles/toastification/";

html,
body,
#app {
	height: 100%;
}

/* transitions */
.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.empty-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	.empty-card-pic {
		width: 255px;
	}

	.empty-card-text {
		margin-top: 30px;
		font-size: 14px;
		line-height: 26px;
		text-align: center;
		letter-spacing: 0.2px;
		color: #1c1c28;
	}
}
</style>