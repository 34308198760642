<template>
	<div class="xf-sidebar">
		<div class="xf-sidebar-inner">
			<div class="sidebar-profile">
				<xf-profile-avatar class="sidebar-profile-avatar" :img="$store.state.User.data.profile" />
				<div class="sidebar-profile-content">
					<div class="sidebar-profile-name">{{$store.state.User.data.user_full_name}}</div>
					<div class="sidebar-profile-desc">{{$store.getters.activeProjectsCount}} Active projects</div>
				</div>
			</div>
			<xf-divider />
			<div class="sidebar-content">

				<div class="sidebar-section" v-for="(section , index) in sideBarSections" :key="index">
					<div class="sidebar-section-title">{{section.sectionTitle}}</div>
					<ul class="sidebar-btns">
						<li v-for="(btn , index) in section.sectionBtns" :key="index">
							<router-link :to="btn.btnLink" active-class="active">
								<div class="btn-content" :class="(btn.btnText == 'Log Out') ? 'danger' : ''" @click="closeMobileSidebar">
									<i :class="btn.btnIcon"></i>
									<span class="sidebar-btn-text">{{btn.btnText}}</span>
									<span class="sidebar-btn-badge" v-if="btn.btnText == 'Notifications'">{{$store.getters.newNotifications.length}}</span>
								</div>
							</router-link>
						</li>
					</ul>
					<xf-divider v-if="index != Object.keys(sideBarSections).length - 1" />
				</div>

			</div>
		</div>

		<div class="sidebar-backdrop" @click="closeMobileSidebar"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			sideBarSections: [
				{
					sectionTitle: "MAIN CONTROLS",
					sectionBtns: [
						{
							btnIcon: "fas fa-home",
							btnText: "Overview",
							btnLink: "/panel/overview",
						},
						{
							btnIcon: "fas fa-file-download",
							btnText: "Documents / Assets",
							btnLink: "/panel/documents",
						},
						{
							btnIcon: "fas fa-comment-dots",
							btnText: "Tickets",
							btnLink: "/panel/support",
						},
					]
				},
				{
					sectionTitle: "GENERAL",
					sectionBtns: [
						{
							btnIcon: "fas fa-cog",
							btnText: "Settings",
							btnLink: "/panel/settings",
						},
						{
							btnIcon: "fas fa-file-alt",
							btnText: "Subscription",
							btnLink: "/panel/subscription",
						},
						{
							btnIcon: "fas fa-bell",
							btnText: "Notifications",
							btnLink: "/panel/notifications",
						},
						{
							btnIcon: "fas fa-sign-out-alt",
							btnText: "Log Out",
							btnLink: "/panel/logout",
						},
					]
				}
			]
		}
	},
	methods: {
		closeMobileSidebar() {
			document.querySelector(".xf-sidebar").classList.remove("show");
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/_variables";

.xf-sidebar {
	width: 255px;
	height: 100%;
	position: fixed;
	left: 0;
	bottom: 0;
	top: 0;
	background-color: #fff;
	transition: left 200ms ease;
	z-index: 10;

	.xf-sidebar-inner {
		padding-bottom: 30px;
		background-color: #fff;
		position: relative;
		z-index: 5;
		height: 100%;
		overflow-y: auto;

		.sidebar-profile {
			padding-top: 33px;
			padding-bottom: 21px;
			display: flex;
			align-items: center;

			.sidebar-profile-avatar {
				margin-left: 32px;
				margin-right: 24px;
				flex: 0 0 auto;
			}

			.sidebar-profile-content {
				.sidebar-profile-name {
					font-weight: 600;
					font-size: 16px;
					line-height: 22px;
					color: #1c1c28;
					margin-bottom: 4px;
				}

				.sidebar-profile-desc {
					font-size: 14px;
					line-height: 24px;
					color: #8f90a6;
				}
			}
		}

		.sidebar-content {
			.sidebar-section-title {
				padding: 20px 32px;
				font-size: 14px;
				line-height: 24px;
				color: #8f90a6;
			}

			.sidebar-btns {
				li {
					a {
						&.active .btn-content {
							background: #f2f5ff;
							color: #0063f7;
						}

						.btn-content {
							padding: 20px 32px;
							display: flex;
							align-items: center;
							color: #8f90a6;
							transition: all 200ms ease;

							&.danger {
								color: #ff3b3b;
							}

							i {
								width: 30px;
								font-size: 20px;
								margin-right: 5px;
							}

							.sidebar-btn-text {
								font-weight: 600;
								font-size: 15px;
								line-height: 19px;
								letter-spacing: 0.2px;
							}

							.sidebar-btn-badge {
								margin-left: auto;
								border-radius: 50%;
								background: #0063f7;
								color: #fff;
								width: 20px;
								height: 20px;
								display: flex;
								justify-content: center;
								align-items: center;
								font-weight: 600;
								font-size: 11px;
								line-height: 15px;
								letter-spacing: 0.2px;
							}
						}
					}
				}
			}
		}
	}

	.sidebar-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		background-color: rgba(0, 0, 0, 0);
		transition: all 200ms ease;
	}

	@include only-phone {
		left: -255px;

		&.show {
			left: 0;

			.sidebar-backdrop {
				pointer-events: initial;
				background-color: rgba(0, 0, 0, 0.35);
			}
		}
	}
}
</style>