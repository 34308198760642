<template>
	<xf-confirm v-model="$parent.disableTwoFA.show" denyText="Disable" :denyLoading="loading.disableTwoFA" @deny="disableTwoFA" @hide="hideEvent">
		<template v-slot:title>Disable Two-factor authentication</template>
		<template v-slot:text>Click the Disable button bleow to disable the Two-factor authentication. You can enable it again anytime.</template>
	</xf-confirm>
</template>

<script>
export default {
    data() {
        return {
            loading:{
                disableTwoFA: false
            }
        }
    },
	methods: {
		hideEvent() {
			if (!this.$parent.disableTwoFA.disabled) { this.$parent.account_settings.twoFA = true; }
		},
		disableTwoFA() {
			this.loading.disableTwoFA = true;

			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			}

			var data = {
				
			}

			this.$http.put('2fa/disable', data, config)
				.then((/* response */) => {
					this.$parent.disableTwoFA.disabled = true;
					this.$toast.success("Two-factor authentication successfully disabled!");
					//var responseData = response.data.data;
					this.$store.commit("setUserData", {
						persist: this.$store.state.User.data_cached,
						userData: {
							twoFA: false,
						}
					})
                    this.$parent.disableTwoFA.show = false;
				})
				.catch((e) => {
					if (e.response.status === 401) {
						/* auth error, redirect user to login page */
						this.$store.commit("unsetUserData");
						this.$router.push("/login");
					} else {
						this.$toast.error("Can't disable two-factor authentication!");
					}
				})
				.finally(() => {
					this.loading.disableTwoFA = false;
				});
		}
	}
}
</script>

<style>
</style>