<template>
	<transition name="fade">
		<div class="xf-confirm" v-if="isShow">
			<div class="xf-confirm-content-wrapper">
				<div class="xf-confirm-content">
					<div class="xf-confirm-title">
						<slot name="title"></slot>
					</div>
					<div class="xf-confirm-text">
						<slot name="text"></slot>
					</div>
					<div class="xf-confirm-btns">
						<XfBtn v-if="confirmText != ''" btnstyle="success" @click="onConfirm" :loading="confirmLoading">
							{{confirmText}}
						</XfBtn>
						<XfBtn v-if="denyText != ''" :text="denyText" btnstyle="danger" @click="onDeny" :loading="denyLoading">
							{{denyText}}
						</XfBtn>
						<XfBtn v-if="cancelText != ''" btnstyle="outline" @click="onCancel" :loading="cancelLoading">
							{{cancelText}}
						</XfBtn>
					</div>
				</div>
			</div>
			<div class="xf-confirm-back" @click="hideModal"></div>
		</div>
	</transition>
</template>

<script>
import XfBtn from '../btns/XfBtn.vue'
export default {
	data() {
		return {
			isShow: this.value,
		}
	},
	props: {
		confirmText: {
			default: "",
			type: String,
		},
		denyText: {
			default: "",
			type: String,
		},
		cancelText: {
			default: "",
			type: String,
		},
		confirmLoading: {
			default: false,
		},
		denyLoading: {
			default: false,
		},
		cancelLoading: {
			default: false,
		},
		value: {
			default: false,
		}
	},
	components: {
		XfBtn
	},
	methods: {
		onConfirm() {
			this.show = false;
			this.$emit('confirm');
		},
		onDeny() {
			this.show = false;
			this.$emit('deny');
		},
		onCancel() {
			this.show = false;
			this.$emit('cancel');
		},
		hideModal() {
			this.isShow = false;
			this.$emit("hide");
			this.$emit("input", this.isShow);
		}
	},
	watch: {
		value(newVal) {
			this.isShow = this.value;
			if (!newVal) this.$emit("hide");
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/_variables";

.xf-confirm {
	.xf-confirm-content-wrapper {
		padding: 0 16px;
		width: 100%;
		max-width: 578px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;

		.xf-confirm-content {
			width: 100%;
			background-color: #fff;
			padding: 32px;
			border-radius: 12px;
			max-height: 90vh;
			overflow-y: auto;

			@include only-phone {
				padding: 16px;
			}

			.xf-confirm-title {
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.2px;
				color: #1c1c28;
				margin-bottom: 24px;

				@include only-phone {
					font-size: 18px;
					line-height: 22px;
					margin-bottom: 20px;
				}
			}

			.xf-confirm-text {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.2px;
				color: #8f90a6;
				margin-bottom: 32px;

				@include only-phone {
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 50px;
				}
			}

			.xf-confirm-btns {
				display: flex;
				align-items: center;

				.xf-btn-wrapper {
					margin-right: 20px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.xf-confirm-back {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #0005;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>