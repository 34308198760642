<template>
	<div class="subscription" v-if="!loading.subscription">
		<div class="row my-gutters">
			<div class="col-12 col-lg-6" v-for="item in subscription" :key="item.subscription_id">
				<xf-section-card class="subscription-card" title="Subscription Plan">
					<div class="plan-details">
						<div class="plan-title">{{item.subscription_title}}</div>

						<div class="plan-price">
							<div class="plan-price-value">${{item.subscription_price_per_month}}</div>
							<div class="plan-price-divider"> / </div>
							<div class="plan-price-period">Monthly</div>
						</div>
					</div>

					<div class="plan-invoice">
						<div class="plan-invoice-date">Next Invoice on Sep 18</div>
						<div class="plan-invoice-remained">(5 Days left)</div>
					</div>

					<div class="card-divider"></div>

					<div class="plan-features">
						<div class="plan-features-title">Features of your current plan:</div>

						<ul class="plan-features-items">
							<li v-for="(feature , index) in item.subscription_content" :key="index">
								<div class="plan-feature-icon">
									<i class="fas fa-check"></i>
								</div>
								<div class="plan-feature-value">
									{{feature}}
								</div>
							</li>
						</ul>
					</div>

					<div class="info-alert">
						In order to cancel or extension subscription contact us.
					</div>

				</xf-section-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				subscription: false,
			},
			subscription: []
		}
	},
	created() {
		/* set loading */
		this.loading.subscription = true;
		this.$root.$emit('panelContainerLoading', true);

		/* call the api */
		const config = {
			headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
		};

		this.$http.get('subscription/my', config)
			.then((response) => {
				var responseData = response.data.data;
				this.subscription = responseData;
			})
			.catch((e) => {
				if (e.response.status === 401) {
					/* auth error, redirect user to login page */
					this.$store.commit("unsetUserData");
					this.$router.push("/login");
				}
			})
			.finally(() => {
				this.loading.subscription = false;
				this.$root.$emit('panelContainerLoading', false);
			});
	},
	destroyed() {
		this.$root.$emit('panelContainerLoading', false);
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Subscription");
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/_variables";

.subscription {
	.subscription-card {
		.plan-details {
			display: flex;
			align-items: center;

			.plan-title {
				font-weight: bold;
				font-size: 16px;
				line-height: 26px;
				letter-spacing: 0.2px;
				color: #0063f7;
				margin-right: 12px;
			}

			.plan-price {
				display: flex;
				align-items: center;

				.plan-price-value {
					font-weight: 600;
					font-size: 12px;
					line-height: 26px;
					letter-spacing: 0.2px;
					color: #252733;
				}

				.plan-price-divider {
					font-weight: 600;
					font-size: 10px;
					line-height: 26px;
					letter-spacing: 0.2px;
					color: #8f90a6;
					margin: 0 5px;
				}

				.plan-price-period {
					font-weight: 600;
					font-size: 10px;
					line-height: 26px;
					letter-spacing: 0.2px;
					color: #8f90a6;
				}
			}
		}

		.plan-invoice {
			display: flex;
			align-items: center;

			.plan-invoice-date {
				font-size: 12px;
				line-height: 26px;
				letter-spacing: 0.2px;
				color: #8f90a6;
				margin-right: 8px;
			}

			.plan-invoice-remained {
				font-weight: 600;
				font-size: 12px;
				line-height: 26px;
				letter-spacing: 0.2px;
				color: #252733;
			}
		}

		.card-divider {
			height: 1px;
			background-color: #c7c9d9;
			margin: 12px -32px;

			@include only-phone-bs-lg {
				margin: 12px -16px;
			}
		}

		.plan-features {
			.plan-features-title {
				font-weight: bold;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.2px;
				color: #252733;
				margin-bottom: 16px;
			}

			.plan-features-items {
				li {
					display: flex;
					align-items: center;
					margin-bottom: 8px;

					&:last-child {
						margin-bottom: 0;
					}

					.plan-feature-icon {
						margin-right: 8px;
					}

					.plan-feature-value {
						font-weight: 600;
						font-size: 12px;
						line-height: 26px;
						letter-spacing: 0.2px;
						color: #252733;
					}
				}
			}
		}

		.info-alert {
			margin-top: 24px;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.2px;
			color: #0063f7;
		}
	}
}
</style>