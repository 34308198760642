<template>
	<div class="login">
		<component :is="currentComponent"></component>
	</div>
</template>

<script>
import EmailPass from "./EmailPass"
import TwoFA from "./TwoFA"

export default {
	components: {
		EmailPass,
		TwoFA
	},
	data() {
		return {
			state: "login",
			loading: {
				login: false,
			},
			loginData: {
				email: "",
				password: "",
				rememberMe: false,
				twoFA: "",
			}
		}
	},
	computed: {
		currentComponent() {
			if (this.state == "login") return "EmailPass";
			if (this.state == "twoFA") return "twoFA";
			return false;
		},
	},
	methods: {
		loginUser() {
			this.loading.login = true;
			var data = {
				email: this.loginData.email,
				password: this.loginData.password,
				ga_code: this.loginData.twoFA,
			}

			var route = (this.loginData.twoFA == "") ? "login" : "2fa";
			this.$http.post(route, data)
				.then(response => {
					var responseData = response.data.data;
					if (!responseData.twoFA) {
						/* set user data */
						this.$store.commit("setUserData", {
							persist: this.loginData.rememberMe,
							userData: {
								user_id: responseData.user_id,
								user_email: responseData.user_email,
								user_phone: responseData.user_phone,
								user_full_name: responseData.user_full_name,
								user_profile_photo: responseData.user_profile_photo,
								user_subscription: responseData.user_subscription,
								twoFA: responseData.twoFA,
								token: responseData.token,
								profile: responseData.user_profile_photo,
							}
						})
						this.$store.commit("changeUserDataValidationStatus", true);

						/* set active projects */
						this.$store.commit("setActiveProjects", responseData.active_projects);

						/* set notifications */
						this.$store.commit("setNewNotifications", responseData.unread_notifications);
						this.$router.push("/panel");
					} else {
						this.state = "twoFA";
					}
				})
				.catch(error => {
					this.$toast.error(error.response.data.error.description);
				})
				.finally(() => {
					this.loading.login = false;
				});
		}
	},
	created() {
		if (this.$store.getters.isUserLoggedIn) this.$router.push("/panel");
	}
}
</script>

<style lang="scss">
.login {
	height: 100%;
}
</style>