import Vue from 'vue'

var local_storage_key = "xdigital_client_panel_user";

export default {
	state: {
		data_validated: false,
		data_cached: false,
		data: {
			user_id: null,
			user_email: null,
			user_phone: null,
			user_full_name: null,
			user_profile_photo: null,
			user_subscription: null,
			twoFA: null,
			token: null,
			profile: null,
		}
	},
	mutations: {
		setUserData(state, payload) {
			state.data.user_id = (payload.userData.user_id !== undefined) ? payload.userData.user_id : state.data.user_id;
			state.data.user_email = (payload.userData.user_email !== undefined) ? payload.userData.user_email : state.data.user_email;
			state.data.user_phone = (payload.userData.user_phone !== undefined) ? payload.userData.user_phone : state.data.user_phone;
			state.data.user_full_name = (payload.userData.user_full_name !== undefined) ? payload.userData.user_full_name : state.data.user_full_name;
			state.data.user_profile_photo = (payload.userData.user_profile_photo !== undefined) ? payload.userData.user_profile_photo : state.data.user_profile_photo;
			state.data.user_subscription = (payload.userData.user_subscription !== undefined) ? payload.userData.user_subscription : state.data.user_subscription;
			state.data.twoFA = (payload.userData.twoFA !== undefined) ? payload.userData.twoFA : state.data.twoFA;
			state.data.token = (payload.userData.token !== undefined) ? payload.userData.token : state.data.token;
			state.data.profile = (payload.userData.profile !== undefined) ? payload.userData.profile : state.data.profile;

			/* set data in local storage if we want */
			if (payload.persist == true) {
				localStorage.setItem(local_storage_key, JSON.stringify(state.data)); //put the object into storage
			}
		},
		unsetUserData(state) {
			state.data.user_id = null;
			state.data.user_email = null;
			state.data.user_phone = null;
			state.data.user_full_name = null;
			state.data.user_profile_photo = null;
			state.data.user_subscription = null;
			state.data.twoFA = null;
			state.data.token = null;
			state.data.profile = null;

			/* remove local storage */
			localStorage.removeItem(local_storage_key); //remove data from storage
		},
		changeUserDataValidationStatus(state, status) {
			state.data_validated = status
		},
		changeUserCachedDataStatus(state, status) {
			state.data_cached = status
		}
	},
	getters: {
		isUserLoggedIn(state) {
			if (state.data.token == null) return false;
			return true;
		}
	},
	actions: {
		initUserData(context) {
			/* check if user data is available in local storage */
			if (localStorage.getItem(local_storage_key) != null) {
				context.commit("changeUserCachedDataStatus", true);
				context.commit("setUserData", { userData: JSON.parse(localStorage.getItem(local_storage_key)) });
			}
		},
		validateUserData(context) {
			return new Promise((resolve, reject) => {
				const config = {
					headers: { Authorization: `Bearer ${context.state.data.token}` }
				};

				Vue.prototype.$http.get('user', config)
					.then((response) => {
						var responseData = response.data.data;
						/* update user info */
						context.commit("setUserData", {
							persist: context.state.data_cached,
							userData: {
								user_id: responseData.user_id,
								user_email: responseData.user_email,
								user_phone: responseData.user_phone,
								user_full_name: responseData.user_full_name,
								user_profile_photo: responseData.user_profile_photo,
								user_subscription: responseData.user_subscription,
								twoFA: responseData.twoFA,
								token: responseData.token,
								profile: responseData.user_profile_photo,
							}
						})
						context.commit("changeUserDataValidationStatus", true)

						/* update active projects */
						context.commit("setActiveProjects", responseData.active_projects);

						/* update last notifications */
						context.commit("setNewNotifications", responseData.unread_notifications);

						resolve(response);
					})
					.catch((response) => {
						context.commit("changeUserDataValidationStatus", false)
						reject(response);
					});
			})
		}
	},
};
