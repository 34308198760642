import { render, staticRenderFns } from "./XfTextarea.vue?vue&type=template&id=0f5ec71e&scoped=true&"
import script from "./XfTextarea.vue?vue&type=script&lang=js&"
export * from "./XfTextarea.vue?vue&type=script&lang=js&"
import style0 from "./XfTextarea.vue?vue&type=style&index=0&id=0f5ec71e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5ec71e",
  null
  
)

export default component.exports