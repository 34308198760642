<template>
	<div class="notifications" v-if="!loading.notifications">
		<div class="notifications-row">

			<div class="notifications-col">
				<xf-section-card class="notifications-card" title="All Notifications" :class="[(notifications.allNotifications.expanded) ? 'expanded' : '']">
					<div class="notifications-card-content-wrapper">
						<div class="content">
							<ul class="notifications-list">
								<li :class="{'not-read' : !item.notification_is_readed}" v-for="item in notifications.allNotifications.data" :key="item.notification_id">
									<span>{{item.notification_content}}</span>
								</li>
							</ul>
						</div>
					</div>

					<template v-slot:footer>
						<div class="notifications-card-footer">
							<button class="show-more-btn" @click="notifications.allNotifications.expanded = true" v-if="!notifications.allNotifications.expanded">Show more</button>
							<button class="show-more-btn" @click="notifications.allNotifications.expanded = false" v-else>Show less</button>
						</div>
					</template>
				</xf-section-card>
			</div>

			<div class="notifications-col">
				<xf-section-card class="notifications-card" title="Projects Log Activity" :class="[(notifications.projectsNotifications.expanded) ? 'expanded' : '']">
					<div class="notifications-card-content-wrapper">
						<div class="content">
							<ul class="notifications-list">
								<li :class="{'not-read' : !item.notification_is_readed}" v-for="item in notifications.projectsNotifications.data" :key="item.notification_id">
									<span>{{item.notification_content}}</span>
								</li>
							</ul>
						</div>
					</div>

					<template v-slot:footer>
						<div class="notifications-card-footer">
							<button class="show-more-btn" @click="notifications.projectsNotifications.expanded = true" v-if="!notifications.projectsNotifications.expanded">Show more</button>
							<button class="show-more-btn" @click="notifications.projectsNotifications.expanded = false" v-else>Show less</button>
						</div>
					</template>
				</xf-section-card>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: {
				notifications: false,
			},
			notifications: {
				allNotifications: {
					expanded: false,
					data: [],
				},
				projectsNotifications: {
					expanded: false,
					data: [],
				}
			}
		}
	},
	created() {
		this.$store.commit("readUnreadNotifications");

		/* set loading */
		this.loading.notifications = true;
		this.$root.$emit('panelContainerLoading', true);

		/* call the api */
		const config = {
			headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
		};

		this.$http.get('notifications', config)
			.then((response) => {
				var responseData = response.data.data;
				responseData.forEach((item) => {
					if (item.notification_type == "notification") this.notifications.allNotifications.data.push(item);
					else this.notifications.projectsNotifications.data.push(item);
				})

				/* set notifications as read */
				var data = {
					notification_ids: []
				}
				responseData.forEach((item) => {
					if(item.notification_is_readed == 0) data.notification_ids.push(item["notification_id"]);
				})
				if(data.notification_ids.length > 0) this.$http.patch('notifications/readall', data, config)
			})
			.catch((e) => {
				if (e.response.status === 401) {
					/* auth error, redirect user to login page */
					this.$store.commit("unsetUserData");
					this.$router.push("/login");
				}
			})
			.finally(() => {
				this.loading.notifications = false;
				this.$root.$emit('panelContainerLoading', false);
			});
	},
	destroyed() {
		this.$root.$emit('panelContainerLoading', false);
	},
	mounted() {
		this.$root.$emit('setPanelTitleText', "Notifications");
	}
}
</script>

<style lang="scss">
@import "../../../assets/styles/_variables";

.notifications {
	height: 100%;

	@include only-phone-bs-lg {
		height: auto;
	}

	.notifications-row {
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		margin: -16px -8px;

		@include only-phone-bs-lg {
			height: auto;
		}

		.notifications-col {
			flex: 0 0 auto;
			width: 50%;
			padding: 16px 8px;
			height: 100%;

			@include only-phone-bs-lg {
				width: 100%;
				height: 330px;
			}
		}
	}

	.notifications-card {
		height: 422px;
		transition: height 200ms ease;
		max-height: 100%;

		&.expanded {
			height: 100% !important;
		}

		.notifications-card-content-wrapper {
			display: flex;
			flex-direction: column;
			height: 100%;
			margin: -8px;

			.content {
				flex: 1;
				display: flex;
				flex-wrap: wrap;

				.notifications-list {
					width: 100%;

					li {
						padding: 16px 0;
						border-bottom: 1px solid #f2f2f5;
						font-size: 16px;
						line-height: 26px;
						letter-spacing: 0.2px;
						color: #252733;

						span {
							vertical-align: middle;
						}

						&.not-read {
							&::after {
								content: "";
								display: inline-block;
								width: 10px;
								height: 10px;
								background: #0063f7;
								border-radius: 50%;
								margin-left: 12px;
								vertical-align: middle;
							}
						}

						&:last-child {
							border: none;
						}
					}
				}
			}
		}

		.notifications-card-footer {
			text-align: center;
			padding-top: 18px;

			@include only-phone-bs-lg {
				display: none;
			}

			.show-more-btn {
				font-weight: 600;
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 0.2px;
				background: none;
				color: #0063f7;
			}
		}
	}
}
</style>
