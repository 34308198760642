<template>
	<div></div>
</template>

<script>
export default {
	created() {
		this.$store.commit("unsetUserData");
		this.$router.push("/login")
	}
}
</script>
