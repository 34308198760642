import Vue from 'vue'
import Vuex from 'vuex'

import Projects from './modules/Projects'
import User from './modules/User'
import Notifications from './modules/Notifications'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    User,
    Projects,
    Notifications,
  }
})
