<template>
	<div class="xf-notifications">
		<div class="xf-notifications-btn-wrapper">
			<button class="xf-notifications-toggle-btn" @click="toggleNotificationSection"><i class="fas fa-bell"></i></button>
			<div class="xf-new-notification-indicator" v-if="$store.getters.userHasNewNotifications"></div>
		</div>

		<div class="xf-notifications-content" v-show="open">
			<div class="xf-notifications-content-top">
				<span class="xf-notifications-title">Notifications</span>
				<button class="xf-notifications-close-btn" @click="closeNotificationSection"><i class="fas fa-times"></i></button>
			</div>

			<div class="xf-empty-notifications" v-if="$store.state.Notifications.data.newNotifications.length == 0">
				<div class="xf-empty-notifications-pic">
					<img src="../../assets/pics/empty_inbox.png">
				</div>
				<div class="xf-empty-notifications-text">You have no notification yet</div>
			</div>

			<div class="xf-with-notifications" v-else>
				<div class="xf-notifications-items">
					<div class="xf-notifications-item" :class="{new: !notification.notification_is_readed}" v-for="(notification , index) in $store.state.Notifications.data.newNotifications" :key="index">
						<span>{{notification.notification_content}}</span>
					</div>
				</div>
				<div class="xf-view-all">
					<button class="xf-view-all-btn" @click="redirectToNotifications">View All Notifications</button>
				</div>
			</div>
		</div>
		<div class="xf-notifications-backdrop" :class="{show: open}" @click="closeNotificationSection"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			open: false,
		}
	},
	methods: {
		redirectToNotifications() {
			this.closeNotificationSection();
			if (this.$router.currentRoute.fullPath != '/panel/notifications') this.$router.push('/panel/notifications');
		},
		toggleNotificationSection() {
			if (this.open) this.closeNotificationSection();
			else this.open = true;
		},
		closeNotificationSection() {
			/* set notifications as read */
			const config = {
				headers: { Authorization: `Bearer ${this.$store.state.User.data.token}` }
			}
			var data = {
				notification_ids: []
			}
			this.$store.getters.newNotifications.forEach((item) => data.notification_ids.push(item["notification_id"]))
			this.$http.patch('notifications/readall', data, config)

			this.$store.commit("readUnreadNotifications");
			this.open = false;
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/_variables";

.xf-notifications {
	position: relative;
	width: max-content;

	.xf-notifications-btn-wrapper {
		position: relative;

		.xf-notifications-toggle-btn {
			background: none;
			i {
				font-size: 24px;
			}
		}

		.xf-new-notification-indicator {
			width: 6px;
			height: 6px;
			background: #ff3b3b;
			outline: 1.5px solid #f4f6f3;
			border-radius: 50%;
			position: absolute;
			right: 2px;
			top: 2px;
		}
	}

	.xf-notifications-content {
		width: 318px;
		padding: 16px;
		background: #ffffff;
		border-radius: 12px;
		position: absolute;
		top: 100%;
		right: 0;
		margin-top: 6px;
		z-index: 5000;

		@include only-phone {
			width: 248px;
		}

		.xf-notifications-content-top {
			display: flex;
			.xf-notifications-title {
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
				text-align: center;
				letter-spacing: 0.2px;
				color: #1c1c28;

				@include only-phone {
					font-size: 14px;
					line-height: 17px;
				}
			}

			.xf-notifications-close-btn {
				margin-left: auto;
				background: none;
			}
		}

		.xf-empty-notifications {
			padding: 32px 32px;

			@include only-phone {
				padding: 16px 10px;
			}

			.xf-empty-notifications-pic {
				img {
					width: 100%;
				}
			}

			.xf-empty-notifications-text {
				font-size: 14px;
				line-height: 26px;
				text-align: center;
				letter-spacing: 0.2px;
				color: #1c1c28;
				margin-top: 8px;
			}
		}

		.xf-with-notifications {
			padding-top: 20px;

			.xf-notifications-items {
				margin: 0 -16px;
				max-height: 250px;
				overflow: auto;

				.xf-notifications-item {
					padding: 6px 16px;
					border-bottom: 1px solid #f2f2f5;
					font-size: 12px;
					line-height: 26px;
					letter-spacing: 0.2px;
					color: #252733;

					@include only-phone {
						line-height: 22px;
					}

					span {
						vertical-align: middle;
						margin-right: 8px;
					}

					&:last-child {
						border-bottom: none;
					}

					&.new::after {
						content: "";
						display: inline-block;
						width: 6px;
						height: 6px;
						background-color: #0063f7;
						border-radius: 50%;
						vertical-align: middle;
					}
				}
			}

			.xf-view-all {
				text-align: center;
				margin-top: 10px;

				.xf-view-all-btn {
					background: none;
					font-weight: 600;
					font-size: 12px;
					line-height: 26px;
					text-align: center;
					letter-spacing: 0.2px;
					color: #0063f7;
				}
			}
		}
	}

	.xf-notifications-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		background-color: rgba(0, 0, 0, 0);
		transition: all 200ms ease;
		z-index: 1;

		&.show {
			pointer-events: initial;
			background-color: rgba(0, 0, 0, 0.35);
		}
	}
}
</style>